var isActive = false,
    INTERVAL = 5000,
    TIMEOUT = 5000,
    URL = "https://kali.tradair.com/img/tia.png";

function ping () {
    var xhr = new XMLHttpRequest(),
        rand = Math.round(Math.random() * 10000000),
        url = URL + "?a=" + rand,
        timeout,
        timestamp = (new Date()).getTime();

    xhr.open("GET", url, true);
    timeout = setTimeout(function () {
        xhr.onload = null;
        xhr.onerror = null;
        self.postMessage(99999);
        delayedPing();
    }, TIMEOUT);
    xhr.onload = function () { clearTimeout(timeout); self.postMessage(timing(timestamp)); delayedPing(); };
    xhr.onerror = function () { clearTimeout(timeout); self.postMessage('Error'); delayedPing(); };

    xhr.send();
}

function timing (n) {
    return (new Date()).getTime() - n;
}

function delayedPing () {
    setTimeout(ping, INTERVAL);
}

self.onmessage = function (e) {
    if (e && e.data) {
        URL = e.data;
    }

    if (!isActive) {
        isActive = true;
        ping();
    }
    else {
        self.postMessage("Busy");
    }
};