import createRequestUrl from '../utils/createRequestUrl'

define([
    "jQuery",
    "Backbone",
    "SockJS",
    "Stomp",
    "bluebird",
    "jQueryExtender"
], function ($, Backbone, SockJS, stomp, Promise ) {

    "use strict";

    const { Stomp } = stomp

    var WsNg = Backbone.Model.extend({

        _TIMEOUT: 500000,
        _MAX_TRIES_OF_RECONNECTIONS: 1,

        _socket: null,
        _stomp: null,
        _deferreds: null,
        _isConnected: false,
        _connected: null,
        _messages: null,
        _callbackQueue: null,
        _numOfReconnections: null,
        _firstConnectionEstablished: null,

        initialize: function () {
            _.bindAll(this, "_openCallback", "_closeCallback", "_callback");
            this._deferreds = {};
            this._messages = [];
            this.callbackQueue("/topic/anonymous.callbacks");
            this._firstConnectionEstablished = false;

            // TODO: Enable if dev environment
            //Promise.longStackTraces();
            this._numOfReconnections = 0;
            this._connected = {};
            this._connected.promise = new Promise(function (resolve, reject) {
                this._connected.resolve = resolve;
                this._connected.reject = reject;
            }.bind(this));
        },

        connectionQuality: function () {

        },

        socketId: function () {

        },

        reconnect: function () {

        },

        getMsgsCounter: function () {
            return 0;
        },

        close: function () {

        },

        isUsingSocketIo: function () {
            return false;
        },

        open: function () {
            var headers = {}, sessionId = sessionStorage.getItem("taSessionId");

            if (sessionId) {
                headers.sessionId = sessionId;
            }

            this._socket = new SockJS(this._getWsConnectionString());
            this._stomp = Stomp.over(this._socket);
            this._stomp.debug = null;
            this._stomp.connect(headers, this._openCallback, this._closeCallback);
            this._wrapMessageHandler();
        },

        getGuid: function () {
            return null;
        },

        sendMessage: function (datatype, parameters) {
            var headers = {}, promise, sessionId;

            sessionId = sessionStorage.getItem("taSessionId");
            if (sessionId) {
                headers.sessionId = sessionId;
            }
            headers.callbackId = $.s16();

            // Not using native promises yet, using bluebird instead
            promise = new Promise(function (resolve, reject) {
                // Saving references to resolve and reject for
                // later handling, mimicking Q's defer behavior
                this._deferreds[headers.callbackId] = {
                    resolve: resolve,
                    reject: reject
                };

                // Sending a message to server via Stomp, using
                // the data type as the queue name. Headers include sessionId
                // and callbackId (generated by the UI), and the body
                // consists of a string representation of the JSON object
                this._stomp.send(datatype, headers, JSON.stringify(parameters));

                // If this is the first callback in our stack, trigger
                // that the stack is no longer empty. Main control will
                // display a "loading" icon.
                if (Object.keys(this._deferreds).length === 1) {
                    this.trigger("awaitingCallbacks");
                }

            }.bind(this))
                .timeout(this._TIMEOUT, this._getTimeoutStr(datatype))
                .catch(this._defaultCatcher.bind(this, headers.callbackId));

            return promise;
        },

        sendMessageMock: function (datatype, parameters, answer) {
            console.log("Sending mock message", datatype, parameters);
            return new Promise(function (resolve, reject) {
                setTimeout(function () {
                    resolve(answer);
                }, 300);
                console.log(reject);
            });
        },

        subscribe: function (topic, callback) {
            var headers = {}, sessionId;

            sessionId = sessionStorage.getItem("taSessionId");
            if (sessionId) {
                headers.sessionId = sessionId;
            }
            return this._stomp.subscribe(topic, callback, headers);
        },

        isConnected: function () {
            return this._isConnected;
        },

        callbackQueue: function (callbackQueue) {
            var result = this._callbackQueue;

            if (typeof callbackQueue !== "undefined") {
                this._callbackQueue = callbackQueue;
                result = this;
            }

            return result;
        },

        _getTimeoutStr: function (datatype) {
            return "Warning! Callback has yet to be received: " + datatype;
        },

        _openCallback: function (data) {
            this._closeTaAlert();
            console.log("Stomp connect success", data, this._callbackQueue);
            this._isConnected = true;
            this._connected.resolve();
            this.subscribe(this._callbackQueue, this._callback);
            this.trigger("open");
            this._numOfReconnections = 0;
            this._firstConnectionEstablished = true;
        },

        _closeCallback: function (data) {
            var title = "Reconnecting...",
                body = "Session disconnected. Trying to reconnect...";
            if (this._numOfReconnections < this._MAX_TRIES_OF_RECONNECTIONS && this._firstConnectionEstablished) {
                taAlert.showFancy(title, body, "fancyElixium");
                this._numOfReconnections++;
                this.open();
            } else {
                this._closeTaAlert();
                this._isConnected = false;
                this.trigger("close");
                console.log("Stomp disconnect", data);
            }
        },

        _closeTaAlert: function () {
            try {
                taAlert.closeCurrentMsg();
            } catch (ex) {
                console.log(ex);
            }
        },

        _callback: function (frame) {
            var callbackBody = frame.body ? JSON.parse(frame.body) : "",
                callbackId = frame.headers.callbackId,
                message = frame.headers.message,
                deferred = this._deferreds[callbackId];

            if (deferred) {
                if (message === "ok") {
                    deferred.resolve(callbackBody);
                }
                else {
                    deferred.reject(message);
                }
            }

            this._removeDeferred(callbackId);
        },

        _removeDeferred: function (callbackId) {
            if (this._deferreds[callbackId]) {
                this._deferreds[callbackId].resolve = null;
                this._deferreds[callbackId].reject = null;
                this._deferreds[callbackId] = null;
                delete this._deferreds[callbackId];
            }

            // If the callback stack is now empty, notify the
            // main control to hide the loading icon
            if (Object.keys(this._deferreds).length === 0) {
                this.trigger("noAwaitingCallbacks");
            }
        },

        _wrapMessageHandler: function () {
            // Wrapping SockJS/Stomp original onmessage
            // handler in order to calculate statistics

            var onMessage = this._socket.onmessage,
                messages = this._messages;

            this._socket.onmessage = function (message) {
                messages.push(message.data);
                onMessage(message);
            }
        },

        _defaultCatcher: function (callbackId, message) {
            this._removeDeferred(callbackId);
            console.warn(message);
            throw message;
        },

        _getWsConnectionString: function () {
            return createRequestUrl('/wsng')
        }

    }); 

    return new WsNg();

});