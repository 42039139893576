define([
    "jQuery",
    "Underscore",
    "Backbone",
    "taAlert"
], function ($, _, Backbone, taAlert) {
    "use strict";
    var cacheLoaderPopupView = Backbone.View.extend({
        _downloadingAlertId: null,
        _cache: window.applicationCache,
        _progressBar: null,
        _frontElem: null,
        _caption: null,
        _div: null,
        _userClosed: false,

        initialize: function () {
            if (this && this._cache) {
                this._cache.addEventListener('cached', $.proxy(this._applicationCacheOnChangeCallback, this), false);
                this._cache.addEventListener('checking', $.proxy(this._applicationCacheOnChangeCallback, this), false);
                this._cache.addEventListener('downloading', $.proxy(this._applicationCacheOnChangeCallback, this), false);
                this._cache.addEventListener('error', $.proxy(this._applicationCacheOnChangeCallback, this), false);
                this._cache.addEventListener('noupdate', $.proxy(this._applicationCacheOnChangeCallback, this), false);
                this._cache.addEventListener('obsolete', $.proxy(this._applicationCacheOnChangeCallback, this), false);
                this._cache.addEventListener('progress', $.proxy(this._applicationCacheOnChangeCallback, this), false);
                this._cache.addEventListener('updateready', $.proxy(this._applicationCacheOnChangeCallback, this), false);
            }
        },

        _showLoadingAlert: function () {
            this._frontElem = $("<div/>")
                .addClass("cacheLoaderPopup_progressBarFront");

            this._progressBar = $("<div/>")
                .addClass("cacheLoaderPopup_progressBar")
                .append($("<div/>").addClass("cacheLoaderPopup_progressBarBack"))
                .append(this._frontElem);

            this._caption = $("<div/>")
                .addClass("cacheLoaderPopup_caption")
                .text("Loading new version...");

            this._div = $("<div/>")
                .addClass("cacheLoaderPopup_inner")
                //.append($("<div/>").addClass("cacheLoaderPopup_logo_default cacheLoaderPopup_logo_" + sessionStorage.getItem("taLogo")))
                .append($("<div/>").addClass("cacheLoaderPopup_logo_elixium"))
                .append(this._progressBar)
                .append(this._caption);

            this._downloadingAlertId = taAlert.show("Loading new version...", this._div, {
                width:443,
                hideCloseButton:true,
                hideOkBtn:true,
                dialogClass: "cacheLoaderPopup_popup"
            });
        },

        _hideButtonClick: function () {
            if (this._downloadingAlertId !== null) {
                taAlert.close(this._downloadingAlertId);
            }
            this._userClosed = true;
            this._downloadingAlertId = null;
            //taAlert.closeCurrentMsg();
        },

        _applicationCacheOnChangeCallback: function (event) {
            var loaded,
                total,
                percent;

            if (!this._div && event.type !== "noupdate" && event.type !== "error" && event.type !== "obsolete") {
                this._showLoadingAlert();
            }
            else if (event.type === "noupdate") {
                this._hideButtonClick();
            }

    //        if (event.type == 'downloading') {
    //            showLoadingAlert();
    //        }

            else if (event.type == 'progress') {
                this.trigger("manifestProgress");
                //Bug fix for iphone/ipad: event.loaded/total returns undefined
                loaded = event.loaded ? event.loaded : 0;
                total = event.total ? event.total : 0;
                if (total != 0) {
                    percent = Math.round(loaded / total * 100);
                    this._frontElem.css("width", percent + "%");
                }
            }
            else if (event.type == 'updateready') {
                if (!this._userClosed) {
                    setTimeout($.proxy(this._updateReadyTimeout, this), 1000);
                }
            }
            else if (event.type == 'error') {
                this.trigger("manifestError");
                if (this._downloadingAlertId != null)
                    taAlert.close(this._downloadingAlertId);
            }
            else if (event.type == 'cached') {
                if (this._downloadingAlertId != null)
                    taAlert.close(this._downloadingAlertId);
            }
            $.c('Cache event:', event.type, event);
        },

        _updateReadyTimeout: function () {
            /*if (this._downloadingAlertId != null) {
                taAlert.close(this._downloadingAlertId);
            }*/

            //this._div = $("<div>");
            //this._div.append($("<p/>").html("New version is ready, the application will now restart.").css("margin", "1em 1em 1.5em 1em"));
            /*taAlert.show("Loading new version...", this._div, {
                height:130,
                hideCloseButton:true,
                hideOkBtn:true,
                buttons:[
                    {
                        value:"Restart",
                        click:$.proxy(this._restartButtonClick, this)
                    }
                ]
            });*/

            if (taAlert.isActive()) {
                this._caption.text("New version is ready, the application will now restart.");

                if (this._cache && this._cache.status == this._cache.UPDATEREADY) {
                    this._cache.swapCache();
                    $.c("Manifest downloaded. Swapping cache.");
                }

                setTimeout($.proxy(this._restartButtonClick, this), 2000);
            }

        },

        _restartButtonClick: function () {
            window.location.href = "/";
            return false;
        }
    });

    if (!$.isMobile()) {
        return new cacheLoaderPopupView();
    }
    else {
        return null;
    }

});
