define([
    "Backbone"
], function (Backbone) {
    "use strict";

    var LoginThemeHelper = Backbone.View.extend({

        determineLogoByUrl: function ($el, logoElem) {
            var href = window.location.href.toLowerCase();
            if (href.indexOf("org=mil") >= 0 || href.indexOf("millennium") >= 0) {
                sessionStorage.setItem("taLogo", "millennium");
            }
            else if (href.indexOf("parfx") >= 0) {
                sessionStorage.setItem("taLogo", "parfx");
                $el.find("h1").hide();
                logoElem.removeClass("login_tradAirLogo").addClass("taSprite_parfx_large").css({
                    height: "10rem",
                    marginBottom: "0.7rem"
                }).after($("<div/>").css({
                    width: "44rem",
                    height: "2.5rem",
                    background: "url(/images/poweredByTradAir.png) no-repeat right",
                    marginBottom: "6rem"
                }));
            }
            else if (href.indexOf("raiff") >= 0) {
                sessionStorage.setItem("taLogo", "raiff");
            }
            else if (href.indexOf("mako") >= 0) {
                sessionStorage.setItem("taLogo", "mako");
            }
            else if (href.indexOf("nedbank") >= 0) {
                sessionStorage.setItem("taLogo", "nedbank");
                logoElem.removeClass("login_tradAirLogo")
                    .addClass("taSprite_logoImage1 login_tradAirLogo_nedbank")
                    .css({ width: "39rem", height: "9rem", margin: "0 auto", marginBottom: "5rem" });
            }
            else if (href.indexOf("novikom") >= 0) {
                sessionStorage.setItem("taLogo", "novikom");
                logoElem.removeClass("login_tradAirLogo")
                    .addClass("taSprite_logoImage1 login_tradAirLogo_novikom")
                    .css({ width: "39rem", height: "11rem", margin: "0 auto", marginBottom: "7rem" });
            }
            else if (href.indexOf("psb") >= 0) {
                sessionStorage.setItem("taLogo", "psb");
                logoElem.removeClass("login_tradAirLogo")
                    .addClass("taSprite_logoImage1 login_tradAirLogo_psb")
                    .css({ width: "41.1rem", height: "11.1rem", margin: "0 auto", marginBottom: "6rem" });
            }
            else if (href.indexOf("mBank") >= 0) {
                sessionStorage.setItem("taLogo", "mBank");
                logoElem.removeClass("login_tradAirLogo")
                    .addClass("taSprite_logoImage1 login_tradAirLogo_mBank")
                    .css({ width: "39rem", height: "15rem", margin: "0 auto", marginBottom: "5rem" });
            }
            else if (href.indexOf("leumi") >= 0) {
                sessionStorage.setItem("taLogo", "leumiLogo");
                logoElem.removeClass("login_tradAirLogo")
                    .addClass("taSprite_logoImage1 login_tradAirLogo_leumiLogo")
                    .css({ width: "39rem", height: "7rem", margin: "0 auto", marginBottom: "3rem" });
            }
            else if (href.indexOf("kyte") >= 0) {
                sessionStorage.setItem("taLogo", "kyte");
            }
            else if (href.indexOf("edgewater") >= 0) {
                sessionStorage.setItem("taLogo", "edgewater");
            }
            else if (href.indexOf("fcStone") >= 0) {
                sessionStorage.setItem("taLogo", "fcStone");
            }
            else if (href.indexOf("bkb") >= 0) {
                sessionStorage.setItem("taLogo", "kantonalbank");
                logoElem.removeClass("login_tradAirLogo")
                    .addClass("taSprite_logoImage1 login_tradAirLogo_kantonalbank")
                    .css({ width: "39rem", height: "9rem", margin: "0 auto", marginBottom: "5rem" });
            }
            else if (href.indexOf("jpMorgan") >= 0) {
                sessionStorage.setItem("taLogo", "jpMorgan");
            }
            else if (href.indexOf("sebgroup") >= 0) {
                sessionStorage.setItem("taLogo", "sebgroup");
            }
            else if (href.indexOf("ndfex") >= 0) {
                sessionStorage.setItem("taLogo", "ndfex");
            }
            else if (href.indexOf("bmo") >= 0) {
                sessionStorage.setItem("taLogo", "bmo");
            }
            else if (href.indexOf("org=yapi") >= 0) {
                sessionStorage.setItem("taLogo", "yapi");
            }
            else if (href.indexOf("baltica") >= 0) {
                sessionStorage.setItem("taLogo", "baltica");
            }
            else if (href.indexOf("btg") >= 0) {
                sessionStorage.setItem("taLogo", "btgBlue");
            }
            else if (href.indexOf("btg") >= 0) {
                sessionStorage.setItem("taLogo", "btg");
            }
            else if (href.indexOf("lumefx") >= 0) {
                sessionStorage.setItem("taLogo", "lumefx");
            }
            else if (href.indexOf("rbs") >= 0) {
                sessionStorage.setItem("taLogo", "rbs");
            }
            else if (href.indexOf("alfa") >= 0) {
                sessionStorage.setItem("taLogo", "alfa");
            }
            else if (href.indexOf("bancoPopular") >= 0) {
                localStorage.setItem("taLogo", "bancoPopular");
            }
            else if (href.indexOf("unicredit") >= 0) {
                localStorage.setItem("taLogo", "unicredit");
            }
            else if (href.indexOf("kuveyt") >= 0) {
                sessionStorage.setItem("taLogo", "kuveyt");
            }
            else if (href.indexOf("kuveytColor") >= 0) {
                sessionStorage.setItem("taLogo", "kuveytColor");
            }
            else if (href.indexOf("lcg") >= 0) {
                localStorage.setItem("taLogo", "lcg");
            }
            else if (href.indexOf("tradertools") >= 0) {
                sessionStorage.setItem("taLogo", "tradertools");
            }
            else if (href.indexOf("barak") >= 0) {
                sessionStorage.setItem("taLogo", "barak");
            }
            else if (href.indexOf("anz") >= 0) {
                sessionStorage.setItem("taLogo", "anz");
            }
            else if (href.indexOf("bloomberg") >= 0) {
                localStorage.setItem("taLogo", "bloomberg");
            }
            else if (href.indexOf("bancoPopolare") >= 0) {
                localStorage.setItem("taLogo", "bancoPopolare");
            }
            else if (href.indexOf("bankOfTokyo") >= 0) {
                localStorage.setItem("taLogo", "bankOfTokyo");
            }
            else if (href.indexOf("standardChartered") >= 0) {
                localStorage.setItem("taLogo", "standardChartered");
            }
            else if (href.indexOf("nomura") >= 0) {
                localStorage.setItem("taLogo", "nomura");
                $el.find("h1").hide();
                logoElem.removeClass("login_tradAirLogo").addClass("taSprite_nomura_large");
            }
            else {
                sessionStorage.setItem("taLogo", "default");
            }
        },

        setUpLogo: function ($el, splashElem) {
            if (sessionStorage.getItem("taLogo") === "parfx") {
                splashElem.attr("src", "/images/parfx-loading.png");
            }
            else if (localStorage.getItem("taLogo") === "nomura") {
                splashElem.attr("src", "/images/nomura-loading.png");
            }
            splashElem.on("load", $.proxy(this._splashImgLoadCallback, this, $el));
        },

        _splashImgLoadCallback: function ($el) {
            $el.find(".login_splashBack").show();
        }

    });

    return (new LoginThemeHelper());
});