define([
    "jQuery",
    "Underscore",
    "Backbone",
    "taAlert",
    "ws",
    "text!/controls/connectionQuality/connectionQualityWorker.js",
    "text!/controls/connectionQuality/connectionQuality.html",
    "text!/controls/connectionQuality/connectionQuality.css"
], function ($, _, Backbone, taAlert, oWs, connectionQualityWorker, connectionQualityTemplate, connectionQualityCss) {
    "use strict";
    return Backbone.View.extend({

        template: _.template(connectionQualityTemplate),

        _index: 0,
        _data: null,
        _timer: null,
        _isActive: false,
        _timeStamp: null,
        _barsElem: null,
        _isVisible: false,
        _iconElem: null,
        _iconInnerElem: null,
        _iconInnerDomElem: null,
        _graphElem: null,
        _worker: null,
        _timingSkew: 0,
        _syncCount: 0,
        _isNotificationVisible: false,
        _notificationCloseCallbackProxy: null,
        _rank: null,

        _REMOTE_URL: "https://www.google.com/textinputassistant/tia.png",
        _TIMEOUT: 2000,
        _INTERVAL: 1000,
        _DATA_AMOUNT: 60,
        _HEIGHT: 213,
        _90_PERCENT_OF_TIMEOUT: null,

        _ohlcOpen: null,
        _ohlcHigh: null,
        _ohlcLow: null,
        _ohlcClose: null,
        _neutralOhlcOpen: null,
        _neutralOhlcHigh: null,
        _neutralOhlcLow: null,
        _neutralOhlcClose: null,

        _prevDraw: 0,

        initialize: function () {
            _.extend(this, Backbone.Events);
            $.loadCss(connectionQualityCss, "connectionQuality");
            this.$el.addClass("connectionQuality_cont");

            this._data = new Array(this._DATA_AMOUNT);
            this._90_PERCENT_OF_TIMEOUT = (9 / 10) * this._TIMEOUT;
            this._notificationCloseCallbackProxy = $.proxy(this._notificationCloseCallback, this);
            this._worker = new Worker(window.URL.createObjectURL(new Blob([connectionQualityWorker])));
            this._worker.onmessage = $.proxy(this._workerMessageCallback, this);
        },

        render: function () {
            var compiledTemplate = this.template({});
            this.$el.html(compiledTemplate);
            this._barsElem = this.$el.find(".connectionQuality_bars");
            this._iconElem = this.$el.find(".connectionQuality_icon");
            this._iconInnerElem = this.$el.find(".connectionQuality_icon_inner");
            this._graphElem = this.$el.find(".connectionQuality_graph");

            this._iconInnerDomElem = this._iconInnerElem.get(0);

            this.delegateEvents();

            return this;
        },

        events: {
            "click .connectionQuality_icon": "_iconClickCallback"
        },

        remove: function () {
            return Backbone.View.prototype.remove.call(this);
        },

        getCont: function () {
            return this.$el;
        },

        show: function () {
            this._isVisible = true;
            this._graphElem.show();
            this._iconElem.addClass("connectionQuality_icon_active");
            this.draw();
        },

        hide: function () {
            this._isVisible = false;
            this._graphElem.hide();
            this._iconElem.removeClass("connectionQuality_icon_active");
        },

        toggle: function () {
            if (this._isVisible) {
                this.hide();
            }
            else {
                this.show();
            }
        },

        start: function () {
            var url = "https://" + window.location.host + "/tia.png";
            this._isActive = true;
            this._worker.postMessage(url);
        },

        stop: function () {
            this._isActive = false;
        },

        add: function (timing, datatype, originalDatatype, calc) {
            var sub = timing, server;

            if (this._isActive) {
                if (calc) {
                    server = this._getEstimatedServerTime();
                    sub = server - timing;
                }

                this.push(sub);

                this._ohlc(sub);
                this._setIcon(sub);
            }
        },

        push: function (value) {
            this._data[this._index++ % this._DATA_AMOUNT] = value;
        },

        draw: function () {
            var i, time, height, margin, visibility, neutral, frag, now;

            if (this._isVisible) {
                now = Date.now();

                if (now - this._prevDraw >= 250) {
                    this._prevDraw = now;

                    this._barsElem.find("*").remove();
                    frag = $(document.createDocumentFragment());

                    for (i = 0; i < this._DATA_AMOUNT; i++) {
                        time = this._data[(i + this._index) % this._DATA_AMOUNT];

                        if (time && time !== "fail") {
                            height = Math.min(parseInt(time, 10) / this._TIMEOUT, 1) * this._HEIGHT;
                            margin = this._HEIGHT - height;
                            visibility = "visible";
                            neutral = ("" + time).indexOf("neutral") >= 0;
                        }
                        else {
                            visibility = "hidden";
                        }

                        frag.prepend($("<div/>").addClass("connectionQuality_bar theme-text-color-1-background").css({
                            height: height,
                            marginTop: margin,
                            visibility: visibility,
                            background: neutral ? "orange" : ""
                        }));
                    }

                    this._barsElem.append(frag);
                }
            }
        },

        xhrPollingTimeout: function () {
            if (this._data[(this._index - 1) % this._DATA_AMOUNT]) {
                this._data[(this._index - 1) % this._DATA_AMOUNT] += "xhr";
                this.draw();
            }

            this._warnUserAboutBadConnection();
        },

        ohlc: function () {
            var result = {};

            result.open = this._ohlcOpen;
            result.high = this._ohlcHigh;
            result.low = this._ohlcLow;
            result.close = this._ohlcClose;

            this._resetOhlcData();

            return result;
        },

        neutralOhlc: function () {
            var result = {};

            result.open = this._neutralOhlcOpen;
            result.high = this._neutralOhlcHigh;
            result.low = this._neutralOhlcLow;
            result.close = this._neutralOhlcClose;

            this._resetNeutralOhlcData();

            return result;
        },

        reportOhlc: function () {
            var ohlc = this.ohlc();

            oWs.sendMessage("9ac567d1-5fd6-11e3-a188-00ffcab1127a", null, $.noop, ohlc.open, ohlc.high, ohlc.low, ohlc.close);
        },

        sync: function (n) {
            if (isNaN(n)) {
                n = 0;
            }

            if (oWs.isUsingSocketIo()) {
                oWs.sendMessage("e4b5108e-5cf0-11e3-90e4-00ffcab1127a", null, $.proxy(this._syncCallback, this, +new Date(), n), null);
            }
        },

        resetSyncData: function () {
            sessionStorage.removeItem("timingSkew");
            sessionStorage.removeItem("syncCount");
        },

        syncFromLocalStorage: function (n) {
            var timingSkew = sessionStorage.getItem("timingSkew"),
                syncCount = sessionStorage.getItem("syncCount");

            if (timingSkew && !isNaN(+timingSkew)) {
                this._timingSkew = timingSkew;
            }

            if (syncCount && !isNaN(+syncCount)) {
                this._syncCount = syncCount;
            }

            return syncCount;
        },

        tooltip: function (host, branch, commit) {
            var title = host;

            if (branch && commit) {
                title = branch + "-" + commit + " @ " + host;
            }

            this.$el.attr("title", title);
        },

        _syncCallback: function (sendTime, n, data) {
            var now = Date.now(),
                server = +data.reportRes.millis,
                mid = (now + sendTime) / 2;

            this._syncCount++;

            if (this._timingSkew === 0) {
                this._timingSkew = mid - server;
            }
            else {
                this._timingSkew = Math.ceil(((this._timingSkew * (this._syncCount - 1))
                    + (mid - server)) / this._syncCount);
                sessionStorage.setItem("timingSkew", this._timingSkew);
            }

            sessionStorage.setItem("syncCount", this._syncCount);

            //console.log("timingSkew", this._timingSkew);

            if (n > 0) {
                setTimeout($.proxy(this.sync, this, n - 1), 1000);
            }
        },

        _getEstimatedServerTime: function () {
            return Date.now() - this._timingSkew;
        },

        _ohlc: function (timing) {
            timing = parseInt(timing, 10);

            if (this._ohlcOpen === null) {
                this._ohlcOpen = timing;
            }

            if (this._ohlcHigh === null || timing > this._ohlcHigh) {
                this._ohlcHigh = timing;
            }

            if (this._ohlcLow === null || timing < this._ohlcLow) {
                this._ohlcLow = timing;
            }

            this._ohlcClose = timing;
        },

        _neutralOhlc: function (timing) {
            timing = parseInt(timing, 10);

            if (this._neutralOhlcOpen === null) {
                this._neutralOhlcOpen = timing;
            }

            if (this._neutralOhlcHigh === null || timing > this._neutralOhlcHigh) {
                this._neutralOhlcHigh = timing;
            }

            if (this._neutralOhlcLow === null || timing < this._neutralOhlcLow) {
                this._neutralOhlcLow = timing;
            }

            this._neutralOhlcClose = timing;
        },

        _resetOhlcData: function () {
            this._ohlcOpen = null;
            this._ohlcHigh = null;
            this._ohlcLow = null;
            this._ohlcClose = null;
        },

        _resetNeutralOhlcData: function () {
            this._neutralOhlcOpen = null;
            this._neutralOhlcHigh = null;
            this._neutralOhlcLow = null;
            this._neutralOhlcClose = null;
        },

        _workerMessageCallback: function (obj) {
            var timing, data;

            if (obj && obj.data && !isNaN(obj.data)) {
                this._neutralOhlc(obj.data);
                this.push(obj.data + "-neutral");
            }
        },

        _warnUserAboutBadConnection: function () {
            if ($.hasUserLayout(12)) {
                if (window.Notification && window.Notification.permission !== "granted") {
                    window.Notification.requestPermission($.proxy(this._showBadConnectionWarning, this));
                }
                else {
                    this._showBadConnectionWarning();
                }
            }
        },

        _showBadConnectionWarning: function () {
            var notification;

            if (!this._isNotificationVisible && window.Notification) {
                notification = new window.Notification("Warning!", {
                    icon: "/images/apple-touch-icon.png",
                    body: "Network issues detected. Please contact system administrator."
                });
                notification.onclose = this._notificationCloseCallbackProxy;
                notification.onshow = $.proxy(this._notificationDisplayCallback, this, notification);
                this._isNotificationVisible = true;
            }
        },

        _notificationDisplayCallback: function (notification) {
            setTimeout($.proxy(this._notificationDisplayTimeoutCallback, this, notification), 30 * 1000);
        },

        _notificationDisplayTimeoutCallback: function (notification) {
            if (notification && typeof notification.close === "function") {
                this._isNotificationVisible = false;
                notification.close();
            }
        },

        _notificationCloseCallback: function () {
            this._isNotificationVisible = false;
        },

        _setIcon: function (n) {
            var rank = 1;

            if (n < 250) {
                rank = 4;
            }
            else if (n < 750) {
                rank = 3;
            }
            else if (n < 1500) {
                rank = 2;
            }
            else {
                rank = 1;
            }

            if (rank !== this._rank) {
                this._iconInnerDomElem.classList.remove("taIcon-quality" + this._rank);
                this._iconInnerDomElem.classList.add("taIcon-quality" + rank);

                this._rank = rank;
            }

        },

        _iconClickCallback: function () {
            if (this._iconElem.hasClass("connectionQuality_icon_active")) {
                // Graph is visible, turn it off
                this.hide();
            }
            else {
                // Show graph
                this.show();
            }
        }

    });
});
