import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import moment from 'moment'
import numeral from 'numeral'

Backbone.$ = $
Backbone._ = _

window.$ = $
window.jQuery = $
window._ = _
window.moment = moment
window.numeral = numeral
