import $ from 'jquery'
import moment from 'moment'
import numeral from 'numeral'

import './string.extend'

$.extend($, {
  isFu: function (obj) {
    return typeof obj === 'function'
  },
  isOb: function (obj) {
    return typeof obj === 'object'
  },
  isSt: function (obj) {
    return typeof obj === 'string'
  },
  isNu: function (obj) {
    return typeof obj === 'number'
  },
  isBo: function (obj) {
    return typeof obj === 'boolean'
  },
  isUn: function (obj) {
    return typeof obj === 'undefined'
  },
  isFl: function (n) {
    return Number(n) === n && n % 1 !== 0
  },
})

$.extend($, {
  isSpecialSubstring: function (phrase, subject, alternateDelimiter) {
    var piece,
      pieces = phrase.split(alternateDelimiter || '*'),
      i = 0,
      lastIndex = -1,
      thisIndex = -1,
      isSubstring = true
    while (isSubstring && i < pieces.length) {
      // Iterate through pieces, stop if failed or no more pieces
      if ((piece = pieces[i++])) {
        // Check only non-empty pieces
        thisIndex = subject.indexOf(piece)
        if (thisIndex > lastIndex) {
          // If this piece is after the previous one
          lastIndex = thisIndex // Mark this piece's index for next piece check
        } else {
          // Break and exit
          isSubstring = false
        }
      }
    }
    return isSubstring
  },

  c: function c(...args) {
    if (console && console.log) {
      return console.log.apply(console, args)
    }
  },

  createProxy: function (f, context) {
    return function () {
      return f.apply(context, arguments)
    }
  },

  proxy: function (f, context) {
    var curriedArgs = Array.prototype.slice.call(arguments, 2)

    if (!$.isFunction(f)) {
      return undefined
    } else if (curriedArgs.length) {
      return function () {
        var allArgs = curriedArgs.slice(0)
        for (var i = 0, n = arguments.length; i < n; ++i) {
          allArgs.push(arguments[i])
        }
        f.apply(context, allArgs)
      }
    } else {
      return $.createProxy(f, context)
    }
  },

  cs: function (o) {
    console.log(JSON.stringify(o, null, 4))
  },

  isInt: function (input) {
    return typeof input == 'number' && parseInt(input) == input
  },

  /*Fix floating point bug!*/
  fixFloatBug: function (val, roundMultiplyer) {
    return Math.round(val * roundMultiplyer) / roundMultiplyer
  },

  queryString: function (name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    var regexS = '[\\?#&]' + name + '=([^&#]*)',
      regex = new RegExp(regexS),
      results = regex.exec(window.location.href)

    if (results == null) return ''
    else return results[1]
  },

  soundPlay: function (which, repeatCount) {
    var repeatFunc,
      version = localStorage.getItem('taClientVersion')

    if (isNaN(repeatCount)) {
      repeatCount = 1
    }

    if (repeatCount > 0) {
      // Concatinating buildId in order to use cached file
      soundEmbed: new Audio(which + '?buildId=' + version)
      $.soundEmbed.play()
      --repeatCount
      if (repeatCount > 0) {
        repeatFunc = function (leftPlays) {
          $.soundEmbed.play()
          if (leftPlays > 0) {
            setTimeout(function () {
              repeatFunc(--leftPlays)
            }, 1000)
          }
        }
        repeatFunc(--repeatCount)
      }
    }
  },

  getDateFromServerFormat: function (dateStr) {
    var dateObj = {},
      newDate,
      splitedStr

    splitedStr = dateStr.split(' ')
    splitedStr[0] = splitedStr[0].split('-')
    splitedStr[1] = splitedStr[1].split('.')
    splitedStr[1][0] = splitedStr[1][0].split(':')
    dateObj.year = splitedStr[0][0]
    dateObj.month = splitedStr[0][1]
    dateObj.day = splitedStr[0][2]
    dateObj.hour = splitedStr[1][0][0]
    dateObj.minutes = splitedStr[1][0][1]
    dateObj.seconds = splitedStr[1][0][2]
    dateObj.milliseconds = splitedStr[1][1]
    newDate = new Date(
      +dateObj.year,
      +dateObj.month - 1,
      +dateObj.day,
      +dateObj.hour,
      +dateObj.minutes,
      +dateObj.seconds,
      +dateObj.milliseconds
    )
    return newDate
  },

  serverDate2Str: function (date) {
    return (
      date.year +
      '-' +
      ('' + (date.month + 1)).lpad('0', 2) +
      '-' +
      date.date +
      ' ' +
      date.hours +
      ':' +
      date.minutes +
      ':' +
      date.seconds +
      '.000'
    )
  },

  convertDateObjectToStr: function (obj) {
    var result = obj

    if (typeof obj === 'object') {
      result =
        obj.year +
        '-' +
        ('' + (obj.month + 1)).lpad(0, 2) +
        '-' +
        ('' + obj.date).lpad(0, 2) +
        ' ' +
        ('' + obj.hours).lpad(0, 2) +
        ':' +
        ('' + obj.minutes).lpad(0, 2) +
        ':' +
        ('' + obj.seconds).lpad(0, 2) +
        '.000'
    }

    return result
  },

  convertDateFromServer: function (dateStr) {
    var newDate,
      pad = function (num, len) {
        return ('' + num).lpad('0', len)
      }

    dateStr = $.convertDateObjectToStr(dateStr)

    newDate = $.getDateFromServerFormat(dateStr)
    newDate.setTime(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000) // 60 sec in min * 1000 ms in sec
    return (
      '' +
      newDate.getFullYear() +
      '-' +
      pad(+newDate.getMonth() + 1, 2) +
      '-' +
      pad(newDate.getDate(), 2) +
      ' ' +
      pad(newDate.getHours(), 2) +
      ':' +
      pad(newDate.getMinutes(), 2) +
      ':' +
      pad(newDate.getSeconds(), 2) +
      '.' +
      pad(newDate.getMilliseconds(), 3)
    )
  },

  convertTaTimeFromServer: function (taTime) {
    var newDate = $.convertTaTime2DateObj(taTime)
    return $.convertDateObj2LocalTime(newDate)
  },

  convertTaTime2DateObj: function (taTime) {
    var newDate = new Date()
    return new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      newDate.getDate(),
      taTime.hour,
      taTime.minute,
      taTime.second,
      taTime.millisecond
    )
  },

  convertDateObj2LocalTimeStr: function (dateObj) {
    var pad = function (num, len) {
      return ('' + num).lpad('0', len)
    }

    dateObj = $.convertDateObj2LocalTime(dateObj)
    return (
      '' +
      dateObj.getFullYear() +
      '-' +
      pad(+dateObj.getMonth() + 1, 2) +
      '-' +
      pad(dateObj.getDate(), 2) +
      ' ' +
      pad(dateObj.getHours(), 2) +
      ':' +
      pad(dateObj.getMinutes(), 2) +
      ':' +
      pad(dateObj.getSeconds(), 2) +
      '.' +
      pad(dateObj.getMilliseconds(), 3)
    )
  },

  convertDateObj2LocalTime: function (dateObj) {
    dateObj.setTime(dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000) // 60 sec in min * 1000 ms in sec
    return dateObj
  },

  hasUserLayout: function (userLayout) {
    var taLogin = $.getTaLoginFromLocalStorage()
    return !!(
      taLogin &&
      taLogin.user &&
      taLogin.user.resourcesMap &&
      taLogin.user.resourcesMap[userLayout]
    )
  },

  hasPermission: function (userLayout) {
    return $.hasUserLayout(userLayout)
  },

  hasResource: function (userLayout) {
    return $.hasUserLayout(userLayout)
  },

  typeOf: function (obj) {
    if (typeof obj == 'object') {
      if (obj.length != undefined) return 'array'
      else return 'object'
    } else return typeof obj
  },

  formatNumberValues: function (nStr, useGreenForPositive) {
    if (nStr === '' || nStr === null || isNaN(nStr)) return nStr
    var x, x1, x2, rgx, result
    nStr += ''
    x = nStr.split('.')
    x1 = x[0]
    x2 = x.length > 1 ? '.' + x[1] : ''
    rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2')
    }
    result = x1 + x2
    if (useGreenForPositive) {
      result =
        "<span class='taGreenBadge theme-badge-green'>" + result + '</span>'
    }

    return result
  },

  formatNumberValuesAndWrapNegative: function (n) {
    var result

    if (n < 0) {
      result = '(' + $.formatNumberValues(n * -1, false) + ')'
    } else {
      result = $.formatNumberValues(n, false)
    }

    return result
  },

  /** Change for example from 1,000,000 to 1000000 */
  deFormatNumberValues: function (nStr) {
    return nStr.replace(/\,/g, '')
  },

  formatNegativeNumbers: function (number) {
    if (isNaN(number)) return number

    number = number + 0
    if (number < 0) {
      number = number * -1
      number = $.formatNumberValues(number, false)
      return '<span>(' + number + ')</span>'
    } else return number
  },

  /** Format the given number: If it's negative than put red color and use brackets and in general add commas */
  formatNumber: function (number, useGreenForPositive) {
    if (!isNaN(number)) {
      if (number < 0) {
        number = $.formatNegativeNumbers(number)
      } else if (number >= 0) {
        number = $.formatNumberValues(number, useGreenForPositive)
      }
    }
    return number
  },

  tableArray2ObjectArray: function (tblArr) {
    var objArr = [],
      i,
      j
    if (tblArr instanceof Array && tblArr.length >= 1) {
      for (i = 1; i < tblArr.length; i++) {
        objArr[objArr.length] = {}
        for (j = 0; j < tblArr[0].length; j++)
          objArr[objArr.length - 1][tblArr[0][j]] = tblArr[i][j]
      }
    }
    return objArr
  },

  capitaliseFirstLetter: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  capitaliseAll: function (string) {
    return _.map(string.split(' '), function (word) {
      return $.capitaliseFirstLetter(word)
    }).join(' ')
  },

  dateStr2unixTimestamp: function (dateStr) {
    if (typeof dateStr === 'string') {
      var splitDate = dateStr.split('/')
      return (
        splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0] + ' 00:00:00'
      )
    } else {
      return dateStr
    }
  },

  tableArray2ObjectArray: function (tblArr) {
    var objArr = [],
      i,
      j

    if (tblArr instanceof Array && tblArr.length >= 1) {
      for (i = 1; i < tblArr.length; i++) {
        objArr[objArr.length] = {}
        for (j = 0; j < tblArr[0].length; j++)
          objArr[objArr.length - 1][tblArr[0][j]] = tblArr[i][j]
      }
    }

    return objArr
  },

  isNumber: function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  },

  amountStr2Number: function (amountStr) {
    var polishedAmountStr = $.isSt(amountStr)
      ? amountStr.toLowerCase()
      : amountStr
    return numeral(polishedAmountStr).value()
  },

  number2AmountStr: function (number, digits) {
    var res = '0',
      amount

    if ($.isNumber(number)) {
      number = +number
      if (number >= 1000000) {
        amount = number / 1000000
        if ($.isNumber(digits)) {
          amount = amount.toFixed(digits)
          if (/\.0+$/.test(amount)) {
            amount = amount.replace(/\.0+$/, '')
          }
        }
        res = '' + $.formatNumber(amount) + 'M'
      } else if (number >= 1000) {
        amount = number / 1000
        if ($.isNumber(digits)) {
          amount = amount.toFixed(digits)
          if (/\.0+$/.test(amount)) {
            amount = amount.replace(/\.0+$/, '')
          }
        }
        res = '' + $.formatNumber(amount) + 'K'
      } else {
        amount = number
        if ($.isNumber(digits)) {
          amount = amount.toFixed(digits)
          if (/\.0+$/.test(amount)) {
            amount = amount.replace(/\.0+$/, '')
          }
        }
        res = '' + $.formatNumber(amount)
      }
    } else {
      res = undefined
    }

    return res
  },

  number2AmountStrWithCommas: function (num) {
    var num = $.number2AmountStr(num),
      numerator = num.slice(-1),
      numWithoutNumerator = num.substring(0, num.length - 1),
      numWithCommas = $.formatNumber(numWithoutNumerator),
      result = '' + numWithCommas + numerator
    return result
  },

  setTableSettingsColAttribute: function (
    oSettings,
    colName,
    attribute,
    value
  ) {
    var i
    for (i = 0; i < oSettings.colList.length; ++i) {
      if (oSettings.colList[i].name === colName) {
        oSettings.colList[i][attribute] = value
        break
      }
    }
  },

  buildNumberHtml: function (val, currency) {
    var res = val

    if (currency.digitsDisplay && currency.digitsDisplay >= 3) {
      if (val) {
        val = val.toFixed(currency.digitsDisplay).split('.')
      }

      res =
        val && currency
          ? val[0] +
          '.' +
          val[1].substr(0, currency.digitsDisplay - 3) +
          '<span class="bigPrice">' +
          val[1].substr(currency.digitsDisplay - 3, 2) +
          '</span>' +
          val[1].substr(currency.digitsDisplay - 1, 1)
          : '-.-----'
    }

    return res
  },

  getTaLoginFromLocalStorage: function () {
    var taLogin = null

    try {
      taLogin = JSON.parse(sessionStorage.getItem('taLogin'))
    } catch (e) {
      //ignore
    }

    return taLogin
  },

  getUserAgentData: function () {
    var result = {},
      userAgent

    if (
      window &&
      window.navigator &&
      window.navigator.userAgent &&
      typeof window.navigator.userAgent.toLowerCase === 'function'
    ) {
      userAgent = window.navigator.userAgent.toLowerCase()

      if (userAgent.indexOf('mobile') >= 0) {
        result.mobile = true
      }

      if (userAgent.indexOf('ipad') >= 0) {
        result.ipad = true
      }

      if (userAgent.indexOf('safari') >= 0) {
        result.safari = true
      }
    }

    return result
  },

  isMobile: function () {
    return false

    var o = $.getUserAgentData(),
      result = false

    if (o.mobile && !o.ipad) {
      result = true
    }

    return result
  },

  isMobileApp: function () {
    return false
    var o = $.getUserAgentData(),
      result = false

    if (o.mobile && !o.safari) {
      result = true
    }

    return result
  },

  roundUpAndFix: function (value, n) {
    var divisor = Math.pow(10, n)
    return (Math.ceil(value * divisor) / divisor).toFixed(n)
  },

  roundUpTo0Or5AndFix: function (value, n) {
    var divided = 5 / Math.pow(10, n),
      rounded = +$.roundUpAndFix(+value - divided, n - 1),
      result

    if (rounded < +value) {
      result = rounded + divided
    } else {
      result = rounded
    }

    return result.toFixed(n)
  },

  roundDownAndFix: function (value, n) {
    var divisor = Math.pow(10, n)
    return (Math.floor(value * divisor) / divisor).toFixed(n)
  },

  roundDownTo0Or5AndFix: function (value, n) {
    var divided = 5 / Math.pow(10, n),
      rounded = +$.roundDownAndFix(+value + divided, n - 1),
      result

    if (rounded > +value) {
      result = rounded - divided
    } else {
      result = rounded
    }

    return result.toFixed(n)
  },

  extractNumber: function (val) {
    var result = null,
      negative = false

    if (!isNaN(val)) {
      result = +val
    } else if (val instanceof $) {
      result = val.text()

      if (result.indexOf('(') !== -1) {
        negative = true
        result = result.replace('(', '').replace(')', '')
      }

      if (result.indexOf(',') !== -1) {
        result = $.amountStr2Number(result)
      }

      result = +result

      if (negative) {
        result = result * -1
      }
    }

    return result
  },

  circularMod: function (a, b) {
    return ((a % b) + b) % b
  },

  getScreenWidth: function () {
    var width

    if ($.hasUserLayout(30)) {
      width = window.innerWidth
    } else {
      // If user does not have wide screen permission,
      // Choosing the smallest width availabe
      width = 1
    }

    return width
  },

  preventDefault: function (e) {
    e.preventDefault()
  },

  findClassNameInContainer: function (container, className) {
    var result = container.find('.' + className)

    if (container.hasClass(className)) {
      result = result.add(container)
    }

    return result
  },

  _isUserOfClientOrgBoolean: null,

  _checkIfUserOfClientOrg: function () {
    var taLogin = $.getTaLoginFromLocalStorage()
    _isUserOfClientOrgBoolean: taLogin && taLogin.orgType === '4'
  },

  isUserOfClientOrg: function () {
    if ($._isUserOfClientOrgBoolean === null) {
      $._checkIfUserOfClientOrg()
    }

    return $._isUserOfClientOrgBoolean
  },

  getSubDomain: function () {
    var result = null

    try {
      result = window.location.href.match(/\/\/([\w-]*)\./)[1]
    } catch (e) {
      $.c('$.getSubDomain Exception', e, e.stack)
    }

    return result
  },

  extractTextFromElement: function (element) {
    var result,
      type = typeof element,
      $element,
      func = 'text'

    if (type === 'string') {
      result = element.toLowerCase()
    } else if (type === 'number') {
      result = element
    } else {
      $element = $(element)
      if ($element.is('input')) {
        func = 'val'
      }
      result = $element[func]().toLowerCase()
    }

    return result
  },

  comparator: function (a, b) {
    var result = 0

    if (a > b) {
      result = 1
    } else if (a < b) {
      result = -1
    }

    return result
  },

  tableComparator: function (a, b) {
    return $.comparator(
      $.extractTextFromElement(a),
      $.extractTextFromElement(b)
    )
  },

  checkboxComparator: function (a, b) {
    return $.comparator(a.prop('checked') ? 1 : 0, b.prop('checked') ? 1 : 0)
  },

  trafficLightComparator: function (a, b) {
    return $.comparator(
      a.hasClass('taSprite_traffic_light_green') ? 1 : 0,
      b.hasClass('taSprite_traffic_light_green') ? 1 : 0
    )
  },

  replaceEmptyStringWithLargeInt: function (a) {
    var result = a

    if (a === '' || a === ' ') {
      result = 9999999
    }

    return result
  },

  tableComparatorNumeric: function (a, b) {
    var x = $.extractTextFromElement(a),
      y = $.extractTextFromElement(b),
      xNum,
      yNum

    xNum = $.replaceEmptyStringWithLargeInt(x)
    yNum = $.replaceEmptyStringWithLargeInt(y)

    xNum = $.string2Number(xNum)
    yNum = $.string2Number(yNum)

    return $.comparator(xNum, yNum)
  },

  string2Number: function (a) {
    var result = a

    if (typeof a === 'string') {
      result = $.amountStr2Number(a)

      if (!result && result !== 0) {
        result = a
      }
    }

    return result
  },

  tableComparatorPercent: function (a, b) {
    var x = $.extractTextFromElement(a),
      y = $.extractTextFromElement(b)

    x = $.replaceEmptyStringWithLargeInt(x)
    y = $.replaceEmptyStringWithLargeInt(y)
    if (typeof x === 'string') x = x.replace('%', '')
    if (typeof y === 'string') y = y.replace('%', '')

    return $.comparator(x, y)
  },

  dateStringToObject: function (date) {
    var d,
      split = date.split('-')

    d = new Date(0)

    if (split.length === 3) {
      d.setDate(split[2])
      d.setMonth(split[1] - 1)
      d.setFullYear(split[0])
    } else {
      // Invalid date will be at the end of sort
      d.setFullYear(2099)
    }

    return d
  },

  dateTimeStringToObject: function (date) {
    var d,
      split = date.split(' ')
    var dateString = split[0]
    var timeString = split[1]

    d = $.dateStringToObject(dateString)

    split = timeString.split('.')
    var hourString = split[0]
    var milliseconds = 0
    if (1 < split.length) {
      // This field is optional
      milliseconds = parseInt(split[1])
    }

    split = hourString.split(':')
    var hours = parseInt(split[0])
    var minutes = parseInt(split[1])
    var seconds = parseInt(split[2])

    d.setHours(hours, minutes, seconds, milliseconds)

    return d
  },

  tableComparatorDate: function (a, b) {
    var x = $.extractTextFromElement(a),
      y = $.extractTextFromElement(b)

    x = $.dateStringToObject(x)
    y = $.dateStringToObject(y)

    return $.comparator(x, y)
  },

  tableComparatorDateTime: function (a, b) {
    var x = $.extractTextFromElement(a),
      y = $.extractTextFromElement(b)

    x = $.dateTimeStringToObject(x)
    y = $.dateTimeStringToObject(y)

    return $.comparator(x, y)
  },

  tableComparatorDateTimeRev: function (a, b) {
    return -$.tableComparatorDateTime(a, b)
  },

  combineArrays: function (arr1, arr2, index) {
    arr1.splice.apply(arr1, [index, 0].concat(arr2))
    return arr1
  },

  colorLuminance: function (hex, lum) {
    var usePound = false,
      num,
      r,
      g,
      b,
      result

    if (hex[0] == '#') {
      hex = hex.slice(1)
      usePound = true
    }

    num = parseInt(hex, 16)

    r = (num >> 16) + lum

    if (r > 255) r = 255
    else if (r < 0) r = 0

    b = ((num >> 8) & 0x00ff) + lum

    if (b > 255) b = 255
    else if (b < 0) b = 0

    g = (num & 0x0000ff) + lum

    if (g > 255) g = 255
    else if (g < 0) g = 0

    result = (g | (b << 8) | (r << 16)).toString(16)

    while (result.length < 6) {
      result = '0' + result
    }

    return (usePound ? '#' : '') + result
  },

  lighten: function (hex, percent) {
    return $.colorLuminance(hex, (percent / 100) * 255)
  },

  darken: function (hex, percent) {
    return $.colorLuminance(hex, (percent / 100) * -255)
  },

  isParFx: function () {
    return $('body').hasClass('parfx')
  },

  formatSpread: function (bidPrice, offerPrice, digits, secondaryCurrency) {
    var tmp

    if (0 === offerPrice || 0 === bidPrice) {
      // Allowing only one digit after the decimal dot.
      // In case of 0, we would like to not-show it.
      return ''
    } else {
      if (secondaryCurrency) {
        // In case of secondary currency, swaping the bid and offer
        // in order to avoid negative spreads
        tmp = offerPrice
        offerPrice = bidPrice
        bidPrice = tmp
      }
      var str = ((offerPrice - bidPrice) * Math.pow(10, digits)).toFixed(1)
      return str.replace('.0', '')
    }
  },

  getSkewedDate: function (skew) {
    var d = new Date()
    d = new Date(+d + (skew ? skew : 0) * 1000 * 60 * 60 * 24)
    return (
      ('' + d.getDate()).lpad('0', 2) +
      '/' +
      ('' + d.getMonth()).lpad('0', 2) +
      '/' +
      d.getFullYear()
    )
  },

  isNdf: function (str) {
    return str && (str.indexOf('/') === -1 || str.indexOf('-') !== -1)
  },

  fixDigits: function (str, digits) {
    var result = str,
      split

    if (result && result.indexOf('.') !== -1) {
      split = result.split('.')
      result = split[0] + '.' + ('' + split[1]).substring(0, digits)
    }

    return result
  },

  orgTypeStrToNum: function (orgTypeStr) {
    var result = null

    switch (orgTypeStr) {
      case 'SYSTEM':
        result = 1
        break
      case 'BANK':
        result = 2
        break
      case 'PLATFORM':
        result = 3
        break
      case 'CLIENT':
        result = 4
        break
      case 'BROKER':
        result = 5
        break
      case 'COUNTER_PARTY':
        result = 6
        break
      case 'TRADING_PARTY':
        result = 7
        break
    }

    return result
  },

  ounceToKg: function (o) {
    return o / 31.99
  },

  kgToOunce: function (k) {
    return k * 31.99
  },

  isMetal: function (ccPair) {
    var result = false,
      str = ccPair

    if (ccPair && ccPair.name) {
      str = ccPair.name
    }

    if (
      str === 'XAG / USD' ||
      str === 'XPT / USD' ||
      str === 'XAU / USD' ||
      str === 'XPD / USD' ||
      str == '12' ||
      str == '13' ||
      str == '32' ||
      str == '50'
    ) {
      result = true
    }

    result = result || (ccPair && ccPair.isMetal)

    return result
  },

  tierEquals: function (o1, o2) {
    if (o1 === o2) return true
    if (o1 === null || o2 === null) return false
    if (o1 !== o1 && o2 !== o2) return true // NaN === NaN
    var t1 = typeof o1,
      t2 = typeof o2,
      length,
      key,
      keySet
    if (t1 == t2) {
      if (t1 == 'object') {
        keySet = {}
        for (key in o1) {
          if (
            key.charAt(0) === '$' ||
            typeof o1[key] === 'function' ||
            key === 'userSessionId'
          )
            continue
          if (!$.tierEquals(o1[key], o2[key])) return false
          keySet[key] = true
        }
        for (key in o2) {
          if (
            !keySet.hasOwnProperty(key) &&
            key.charAt(0) !== '$' &&
            o2[key] !== undefined &&
            !(typeof o2[key] === 'function')
          )
            return false
        }
        return true
      }
    }
    return false
  },

  isGuid: function (str) {
    var result = false

    if (
      str.length === 36 &&
      str.substr(8, 1) === '-' &&
      str.substr(13, 1) === '-' &&
      str.substr(18, 1) === '-' &&
      str.substr(23, 1) === '-'
    ) {
      result = true
    }

    return result
  },

  swap: function (arr, i, j) {
    var temp = arr[i]
    arr[i] = arr[j]
    arr[j] = temp
  },

  dualSwap: function (arr1, arr2, i, j) {
    $.swap(arr1, i, j)
    $.swap(arr2, i, j)
  },

  hourStrToMs: function (str) {
    var d = new Date(),
      split = str.split('.'),
      ms = split[1],
      h,
      m,
      s

    split = split[0].split(':')
    h = split[0]
    m = split[1]
    s = split[2]

    d.setHours(h)
    d.setMinutes(m)
    d.setSeconds(s)
    d.setMilliseconds(ms)

    return +d
  },

  hourComparator: function (a, b) {
    a = $.hourStrToMs(a)
    b = $.hourStrToMs(b)
    return a === b ? 0 : a > b ? -1 : 1
  },

  dualOrder: function (arr1, arr2, fn) {
    var i,
      n = arr1.length,
      m

    if (arr1.length === arr2.length) {
      while (n) {
        m = 0
        for (i = 1; i < n; i++) {
          if (fn(arr1[i - 1], arr1[i]) > 0) {
            $.dualSwap(arr1, arr2, i - 1, i)
            m = i
          }
        }
        n = m
      }
    }
  },

  sessionId: function () {
    return sessionStorage.getItem('taSessionId')
  },

  isUndefined: function (val1, val2) {
    return val1 ? val1 : val2
  },
  findInElemByClassIncl: function (elem, cls) {
    var result = null

    if (elem && elem.hasClass(cls)) {
      result = elem
    } else if (elem) {
      result = elem.find('.' + cls)
    }

    return result
  },

  dataTypes: function () {
    return [
      'AUTHENTICATE',
      'START_SUBSCRIPTION',
      'STOP_SUBSCRIPTION',
      'LOGIN',
      'LOGOUT',
      'START_TIER',
      'STOP_TIER',
      'ADDORDER',
      'ADD_BID_AND_OFFER',
      'UNRISK_AND_START_ALL_TIERS',
      'RISKREQ',
      'RISK_STATUS',
    ]
  },

  themes: function () {
    return [
      { guid: 'default', name: 'Default' },
      { guid: 'default2', name: 'Default2 (dark theme)' },
      { guid: 'alfa', name: 'Alfa' },
      { guid: 'ams', name: 'Ams' },
      { guid: 'bankOfTokyo', name: 'Bank of Tokyo' },
      { guid: 'baltica', name: 'Baltica' },
      { guid: 'btgBlue', name: 'BTG Pactual' },
      { guid: 'btg', name: 'BTG Pactual Light' },
      { guid: 'lumefx', name: 'LumeFX' },
      { guid: 'rbs', name: 'RBS' },
      { guid: 'bancoPopolare', name: 'Banco Popolare' },
      { guid: 'bancoPopular', name: 'Banco Popular' },
      { guid: 'bloomberg', name: 'Bloomberg' },
      { guid: 'bmo', name: 'BMO' },
      { guid: 'kuveytColor', name: 'Kuveyt Color' },
      { guid: 'kuveyt', name: 'Kuveyt Turk' },
      { guid: 'lcg', name: 'LCG' },
      { guid: 'mako', name: 'Mako' },
      { guid: 'millennium', name: 'Millennium' },
      { guid: 'ndfex', name: 'NDFeX' },
      { guid: 'nedbank', name: 'Nedbank' },
      { guid: 'edgewater', name: 'Edgewater' },
      { guid: 'mBank', name: 'mBank' },
      { guid: 'kantonalbank', name: 'Basler' },
      { guid: 'jpMorgan', name: 'J.P.Morgan' },
      { guid: 'kyte', name: 'Kyte' },
      { guid: 'fcStone', name: 'FCStone' },
      { guid: 'parfx', name: 'ParFX' },
      { guid: 'raiff', name: 'Raiffeisen' },
      { guid: 'sebgroup', name: 'SEB' },
      { guid: 'standardChartered', name: 'Standard Chartered' },
      { guid: 'tradertools', name: 'Trader Tools' },
      { guid: 'barak', name: 'Barak Capital' },
      { guid: 'anz', name: 'ANZ Blue' },
      { guid: 'anzDark', name: 'ANZ Dark' },
      { guid: 'leumiLogo', name: 'Leumi Logo' },
      { guid: 'unicredit', name: 'UniCredit' },
      { guid: 'white', name: 'TradAir White' },
      { guid: 'yapi', name: 'YapiKredi' },
    ]
  },

  stringEqualsIgnoreCase: function (a, b) {
    var result = a === b

    if (
      !result &&
      a &&
      b &&
      typeof a.toUpperCase === 'function' &&
      typeof b.toUpperCase === 'function'
    ) {
      result = a.toUpperCase() === b.toUpperCase()
    }

    return result
  },

  stringInArrayIgnoreCase: function (str, arr) {
    var result = false,
      i

    for (i = 0; i < arr.length && !result; i++) {
      if ($.stringEqualsIgnoreCase(str, arr[i])) {
        result = true
      }
    }

    return result
  },

  sortByObjText: function (a, b) {
    var result = 0

    if (a.text > b.text) {
      result = 1
    } else if (a.text < b.text) {
      result = -1
    }

    return result
  },

  sortByObjSecondary: function (a, b) {
    var result = 0

    if (a.secondary > b.secondary) {
      result = 1
    } else if (a.secondary < b.secondary) {
      result = -1
    }

    return result
  },

  sortByDate: function (a, b) {
    return new Date(a.date) - new Date(b.date)
  },

  amountStringValueComparator: function (a, b) {
    var result = 0

    if ($.amountStr2Number(a.value) > $.amountStr2Number(b.value)) {
      result = 1
    } else if ($.amountStr2Number(a.value) < $.amountStr2Number(b.value)) {
      result = -1
    }

    return result
  },

  hasPowerFill: function (platformName) {
    return (
      platformName &&
      (platformName.indexOf('Barx') !== -1 ||
        platformName.indexOf('CS') !== -1 ||
        platformName.indexOf('ampm') !== -1)
    )
  },

  isAggPlatform: function (ccPair) {
    return ccPair && ccPair.marketSrc === 'Agg'
  },

  isUndefined: function (val1, val2) {
    return typeof val1 !== 'undefined' ? val1 : val2
  },

  oppositeDirection: function (direction) {
    var result = null

    if ($.stringEqualsIgnoreCase(direction, 'bid')) {
      result = 'Offer'
    } else if ($.stringEqualsIgnoreCase(direction, 'offer')) {
      result = 'Bid'
    }

    return result
  },

  debounce: function (func, wait, options) {
    var args,
      maxTimeoutId,
      result,
      stamp,
      thisArg,
      timeoutId,
      trailingCall,
      lastCalled = 0,
      maxWait = false,
      trailing = true

    if (typeof func !== 'function') {
      throw new TypeError()
    }
    wait = Math.max(0, wait) || 0
    if (options === true) {
      var leading = true
      trailing = false
    } else if (options) {
      leading = options.leading
      maxWait = 'maxWait' in options && (Math.max(wait, options.maxWait) || 0)
      trailing = 'trailing' in options ? options.trailing : trailing
    }
    var delayed = function () {
      var remaining = wait - (Date.now() - stamp)
      if (remaining <= 0) {
        if (maxTimeoutId) {
          clearTimeout(maxTimeoutId)
        }
        var isCalled = trailingCall
        maxTimeoutId = timeoutId = trailingCall = undefined
        if (isCalled) {
          lastCalled = Date.now()
          result = func.apply(thisArg, args)
          if (!timeoutId && !maxTimeoutId) {
            args = thisArg = null
          }
        }
      } else {
        timeoutId = setTimeout(delayed, remaining)
      }
    }

    var maxDelayed = function () {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      maxTimeoutId = timeoutId = trailingCall = undefined
      if (trailing || maxWait !== wait) {
        lastCalled = Date.now()
        result = func.apply(thisArg, args)
        if (!timeoutId && !maxTimeoutId) {
          args = thisArg = null
        }
      }
    }

    return function () {
      args = arguments
      stamp = Date.now()
      thisArg = this
      trailingCall = trailing && (timeoutId || !leading)

      if (maxWait === false) {
        var leadingCall = leading && !timeoutId
      } else {
        if (!maxTimeoutId && !leading) {
          lastCalled = stamp
        }
        var remaining = maxWait - (stamp - lastCalled),
          isCalled = remaining <= 0

        if (isCalled) {
          if (maxTimeoutId) {
            maxTimeoutId = clearTimeout(maxTimeoutId)
          }
          lastCalled = stamp
          result = func.apply(thisArg, args)
        } else if (!maxTimeoutId) {
          maxTimeoutId = setTimeout(maxDelayed, remaining)
        }
      }
      if (isCalled && timeoutId) {
        timeoutId = clearTimeout(timeoutId)
      } else if (!timeoutId && wait !== maxWait) {
        timeoutId = setTimeout(delayed, wait)
      }
      if (leadingCall) {
        isCalled = true
        result = func.apply(thisArg, args)
      }
      if (isCalled && !timeoutId && !maxTimeoutId) {
        args = thisArg = null
      }
      return result
    }
  },

  blink: function (elems, func) {
    var i,
      length = elems.size()

    for (i = 0; i < length; i++) {
      elems.get(i).classList.add('taGlow')
    }

    func()
  },

  unBlink: function (elems) {
    var i,
      length = elems.size()

    for (i = 0; i < length; i++) {
      elems.get(i).classList.remove('taGlow')
    }
  },

  isMLSlippagePlatform: function (ccPair) {
    var result = false

    if (
      ccPair &&
      ccPair.marketSrc &&
      !$.isUserOfClientOrg() &&
      typeof ccPair.marketSrc.toLowerCase === 'function' &&
      ccPair.marketSrc.toLowerCase().indexOf('tradertools') !== -1
    ) {
      result = true
    }

    return result
  },

  parseMode: function (str, sweep) {
    var result = ''

    if ($.stringEqualsIgnoreCase(str, 'vwap')) {
      result = 'PT'

      if (sweep) {
        result = 'VWP'
      }
    }

    return result
  },

  parseTif: function (str) {
    var result = str

    if ($.stringEqualsIgnoreCase(str, 'market')) {
      result = 'MKT'
    }

    return result
  },

  avoidOrderPopup: function () {
    // If user has "market double click" or "single click trading" permission,
    // we do not need to show confirmation popup upon adding order.
    return $.hasUserLayout(29) || $.hasUserLayout(38)
  },

  browser: { chrome: true },

  numberValidator: function (e) {
    var isValid = false

    if (e.keyCode === 8 || e.keyCode === 46) {
      isValid = true // backspace, delete
    } else if (e.keyCode === 37 || e.keyCode === 39) {
      isValid = true // left and right arrows
    } else if (e.keyCode === 190) {
      isValid = true // dot
    } else if (e.keyCode >= 48 || e.keyCode <= 57) {
      isValid = true // numbers
    }

    if (!isValid) {
      e.preventDefault()
    }

    return isValid
  },

  createCurrencyMask: function (digitsDisplay) {
    return new RegExp('[0-9]{1,4}(.[0-9]{0,' + (digitsDisplay || 5) + '})?')
  },

  createAmountMask: function () {
    return new RegExp('[0-9]+')
  },

  createAmountMaskAllowShortcuts: function () {
    return new RegExp('[0-9]+.?[0-9]?[MmKk]?[0-9]*')
  },

  create24HourTypingMask: function () {
    return new RegExp(
      '^([012]|([01]\\d|2[0-3])|(([01]\\d|2[0-3]):)|((([01]\\d|2[0-3]):[0-5]))|((([01]\\d|2[0-3]):[0-5]\\d)))$'
    )
  },

  create24HourMask: function () {
    return new RegExp('^([01]\\d|2[0-3]):[0-5]\\d$')
  },

  getUTCString: function (date) {
    var result = ''

    if (date) {
      result = result + (date.getUTCFullYear() + '').lpad('0', 2) + '-'
      result = result + (date.getUTCMonth() + 1 + '').lpad('0', 2) + '-'
      result = result + (date.getUTCDate() + '').lpad('0', 2) + ' '
      result = result + (date.getUTCHours() + '').lpad('0', 2) + ':'
      result = result + (date.getUTCMinutes() + '').lpad('0', 2) + ':'
      result = result + (date.getUTCSeconds() + '').lpad('0', 2)
    }

    return result
  },

  getNonUTCString: function (date) {
    var result = ''

    if (date) {
      result = result + (date.getFullYear() + '').lpad('0', 2) + '-'
      result = result + (date.getMonth() + 1 + '').lpad('0', 2) + '-'
      result = result + (date.getDate() + '').lpad('0', 2) + ' '
      result = result + (date.getHours() + '').lpad('0', 2) + ':'
      result = result + (date.getMinutes() + '').lpad('0', 2) + ':'
      result = result + (date.getSeconds() + '').lpad('0', 2)
    }

    return result
  },

  dateAndTime: function (date, time) {
    var split = time.split(':')

    try {
      date.setHours(+split[0])
      date.setMinutes(+split[1])
    } catch (e) {
      date = null
    }

    return date
  },

  window: $(window),

  empty: function (element) {
    var child
    while ((child = element.lastChild)) {
      element.removeChild(child)
    }
  },

  createMinMaxValidator: function (min, max) {
    return function (value) {
      var n = $.amountStr2Number(value)
      return n >= min && n <= max
    }
  },

  timeStampToShortStr: function (timestamp) {
    var d = new Date(timestamp)
    return (
      ('' + (d.getMonth() + 1)).lpad('0', 2) +
      '-' +
      ('' + d.getDate()).lpad('0', 2) +
      ' ' +
      ('' + d.getHours()).lpad('0', 2) +
      ':' +
      ('' + d.getMinutes()).lpad('0', 2)
    )
  },

  fixViewPort: function () {
    var content =
      'width=' +
      screen.availWidth +
      ', height=' +
      screen.availHeight +
      ', user-scalable=no'

    document
      .querySelector('meta[name=viewport]')
      .setAttribute('width', screen.availWidth)
    document
      .querySelector('meta[name=viewport]')
      .setAttribute('height', screen.availHeight)
    document
      .querySelector('meta[name=viewport]')
      .setAttribute('content', content)
  },

  removeClassWildcard: function (elem, wildcard) {
    var i,
      cls,
      classList = elem.className.split(' ')

    for (i = 0; i < classList.length; i++) {
      cls = classList[i]
      if (cls.indexOf(wildcard) !== -1) {
        elem.classList.remove(cls)
      }
    }
  },

  deepCopy: function (o) {
    var copy = o,
      k

    if (o && typeof o === 'object') {
      copy = Object.prototype.toString.call(o) === '[object Array]' ? [] : {}
      for (k in o) {
        copy[k] = $.deepCopy(o[k])
      }
    }

    return copy
  },

  concatArray: function (arr1, arr2) {
    Array.prototype.push.apply(arr1, arr2)
    return arr1
  },

  generateGuid: function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      $._generateGuidHelper
    )
  },

  _generateGuidHelper: function (cc) {
    var rr = (Math.random() * 16) | 0
    return (cc === 'x' ? rr : (rr & 0x3) | 0x8).toString(16)
  },

  isValidDigitsDisplay: function (price, digitsDisplay) {
    var result = true,
      priceStr = '' + price,
      priceSplit = priceStr.split('.')

    if (priceSplit.length === 2) {
      result = priceSplit[1].length <= digitsDisplay
    }

    return result
  },

  halfSpace: function (str) {
    var result = str

    // Replace spaces with a less wider white space character
    if (typeof str === 'string') {
      result = str.replace(/\s/g, '&#8202;')
    }

    return result
  },

  removeWhiteSpaces: function (str) {
    var result = str

    if (typeof str === 'string') {
      result = str.replace(/\s/g, '')
    }

    return result
  },

  isForwardOrSwap: function (productType) {
    var result = false,
      productTypeName = productType

    if (productType && productType.productTypeName) {
      productTypeName = productType.productTypeName
    }

    if (
      $.stringEqualsIgnoreCase(productTypeName, 'swap') ||
      $.stringEqualsIgnoreCase(productTypeName, 'forward') ||
      $.stringEqualsIgnoreCase(productTypeName, 'fwd')
    ) {
      result = true
    }

    return result
  },

  removeDriverAttr: function (str) {
    var result = str,
      match

    if (typeof str === 'string') {
      match = str.match(/_streaming$/) || str.match(/_trading$/)

      if ($.isArray(match) && match.length >= 1) {
        result = result.replace(match[0], '')
      }
    }

    return result
  },

  loginGuid: function () {
    return (
      $.s4() +
      $.s4() +
      '-' +
      $.s4() +
      '-' +
      $.s4() +
      '-' +
      $.s4() +
      '-' +
      $.s4() +
      $.s4() +
      $.s4()
    )
  },

  s16: function () {
    return $.loginGuid()
  },

  s4: function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  },

  /*
   * Iterate through relevant events and bind /elem/ to each, in his proper context
   */
  delegateEvents: function (elem, events, context) {
    var event, match, eventName, selector, callback

    for (event in events) {
      match = event.match(/^(\S+)\s*(.*)$/)
      eventName = match[1] + '.taDelegateEvents' + context.cid // Namedspace support through Backbone's ID and our own identifying class
      selector = match[2]
      callback = $.proxy(context[events[event]], context) // Generate the callback from Context's method

      elem.on(eventName, selector, callback)
    }
  },

  unDelegateEvents: function (elem, context) {
    elem.off('.taDelegateEvents' + context.cid)
  },
  productTypeIdToName: function (id) {
    var result = null

    switch (id) {
      case 1:
        result = 'SPOT'
        break
      case 2:
        result = 'SWAP'
        break
      case 3:
        result = 'FORWARD'
        break
      case 4:
        result = 'VANILLAOPTION'
        break
    }

    return result
  },

  swapNodes: function (a, b) {
    var aparent = a.parentNode,
      asibling = a.nextSibling === b ? a : a.nextSibling

    b.parentNode.insertBefore(a, b)
    aparent.insertBefore(b, asibling)
  },

  endsWith: function (str, char) {
    var result = false

    if (typeof str === 'string' && typeof char === 'string') {
      result = str[str.length - 1] === char
    }

    return result
  },

  isValidAmount: function (ccPair, amount) {
    return ccPair && amount >= ccPair.minSize && amount <= ccPair.maxSize
  },

  isForwardOrSwap: function (productType) {
    var result = false,
      productTypeName = productType

    if (productType && productType.productTypeName) {
      productTypeName = productType.productTypeName
    }

    if (
      $.stringEqualsIgnoreCase(productTypeName, 'swap') ||
      $.stringEqualsIgnoreCase(productTypeName, 'forward') ||
      $.stringEqualsIgnoreCase(productTypeName, 'fwd')
    ) {
      result = true
    }

    return result
  },

  removeDriverAttr: function (str) {
    var result = str,
      match

    if (typeof str === 'string') {
      match = str.match(/_streaming$/) || str.match(/_trading$/)

      if ($.isArray(match) && match.length >= 1) {
        result = result.replace(match[0], '')
      }
    }

    return result
  },

  blurOnShortcut: function (elem) {
    $(elem).on('keyup', function (e) {
      if (e.keyCode === 75 || e.keyCode === 77) {
        this.blur()
      }
    })
    return elem
  },

  serverDateFormatConst: function () {
    return 'DD/MM/YYYY HH:mm:ss'
  },

  emailValidator: function (email, nullable) {
    return (
      typeof email === 'string' &&
      (/^([A-Za-z0-9_!#%&'/=`{|}~\-.$*?+^])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
        email
      ) ||
        (email === '' && nullable))
    )
  },

  passwordValidator: function (password) {
    // This regex checks if the password matches ALL of the following criteria:
    // 1. At least 8 chars long
    // 2. At least one capital letter
    // 3. At least one small letter
    // 4. At least one number
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password)
  },

  nonEmptyStringValidator: function (string) {
    return typeof string === 'string' && string !== ''
  },

  //Normal int is positive and whole number: "1" ==> true, "1.5" ==> false, "-1" ==> false
  stringIsNormalIntValidator: function (str) {
    var n = ~~Number(str)
    return String(n) === str && n >= 0
  },

  stringIsNormalIntGreaterThanOneValidator: function (str) {
    var n = ~~Number(str)
    return String(n) === str && n >= 1
  },

  stringIsNormalIntGreaterThanZeroValidator: function (str) {
    var n = ~~Number(str)
    return String(n) == str && n >= 0
  },

  stringIsNormalIntOrEmptyValidator: function (str) {
    var n = ~~Number(str)
    return (String(n) === str || n === str || str === '') && n >= 0
  },

  isNumberGreaterThanZeroOrEmptyValidator: function (string) {
    var n = ~~Number(string)
    return ($.isNumber(string) && n >= 0) || string === ''
  },

  isNumberValidator: function (string) {
    return (
      $.isNumber(string) ||
      (string != '' && typeof string === 'string' && !isNaN(string))
    )
  },

  isNumberOrEmptyValidator: function (string) {
    return (
      $.isNumber(string) ||
      (string != '' && typeof string === 'string' && !isNaN(string)) ||
      string === ''
    )
  },

  stringLengthShorterThanValidator: function (length) {
    return function (str) {
      return typeof str === 'string' && str !== '' && str.length <= length
    }
  },

  underscoreToCamelCase: function (str) {
    return str.toLowerCase().replace(/_([a-z])/g, function (g) {
      return g[1].toUpperCase()
    })
  },

  underscoreToSentence: function (str) {
    return $.capitaliseFirstLetter(
      str
        .toLowerCase()
        .replace(/_([a-z])/g, function (g) {
          return ' ' + g[1].toUpperCase()
        })
        .trim()
    )
  },

  camelCaseToUnderscore: function (str) {
    return str.replace(/([A-Z])/g, function ($1) {
      return '_' + $1.toLowerCase()
    })
  },

  camelCaseToSentence: function (str) {
    var result = str

    if (typeof str === 'string') {
      result = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    }

    return result
  },

  lightenDarkenColor: function (col, amt) {
    var usePound = false,
      num,
      red,
      green,
      blue

    if (col[0] == '#') {
      col = col.slice(1)
      usePound = true
    }

    num = parseInt(col, 16)
    red = (num >> 16) + amt

    if (red > 255) red = 255
    else if (red < 0) red = 0

    blue = ((num >> 8) & 0x00ff) + amt

    if (blue > 255) blue = 255
    else if (blue < 0) blue = 0

    green = (num & 0x0000ff) + amt

    if (green > 255) green = 255
    else if (green < 0) green = 0

    return (
      (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16)
    )
  },

  readOnlyElement: function (element, state) {
    element.disabled = !!state
    element.classList.toggle('theme-disabled', !!state)
  },

  dateTimeFormat: function () {
    return 'DD MMM YYYY HH:mm:ss'
  },

  dateFormatClean: function () {
    return 'DD MMM YYYY'
  },

  dateFormatReports: function () {
    return 'YYYYMMDD'
  },

  roudUpAccordingToNano: function (date_arr) {
    var nano = moment(date_arr).format('S')
    return nano > 4
      ? moment(date_arr).add(1, 'second').format($.dateTimeFormat())
      : moment(date_arr).format($.dateTimeFormat())
  },

  dateFormat: function () {
    return 'DD-MMM-YYYY'
  },

  dateFormatMonth: function () {
    return 'DD-MM-YYYY'
  },

  java8ParseTime: function (arr_java8Time) {
    var m = moment.utc()
    if (!arr_java8Time) {
      return ''
    }
    m.hours(arr_java8Time[0])
    m.minutes(arr_java8Time[1])
    return moment(m).format('HH:mm')
  },

  java8ParseTimeToLocal: function (java8Time) {
    if (!java8Time) {
      return ''
    }
    const utcTime = moment.utc(java8Time, 'HH:mm')
    utcTime.local()
    return utcTime.format('HH:mm')
  },

  java8ParseTimeToLocalAMPM: function (arr_java8Time) {
    var m = moment.utc()
    if (!arr_java8Time) {
      return ''
    }
    m.hours(arr_java8Time[0])
    m.minutes(arr_java8Time[1])
    return moment(m).local().format('HH:mm A')
  },

  java8ParseDate: function (arr_data) {
    var result = ''
    if (arr_data) {
      try {
        arr_data[1]-- //months in moment are 0-indexed, but in Java 8 they are 1-indexed
        if (arr_data[6]) {
          delete arr_data[6]
        }
      } catch (ex) {
        console.log(ex.message)
      }
      result = $.roudUpAccordingToNano(arr_data)
    }
    return result
  },

  java8ParseDateFormat: function (arr_data) {
    var result = '',
      m = {}
    if (arr_data) {
      try {
        arr_data[1]-- //months in moment are 0-indexed, but in Java 8 they are 1-indexed
        if (arr_data[6]) {
          delete arr_data[6]
        }
      } catch (ex) {
        console.log(ex.message)
      }
      if (arr_data) {
        m.year = arr_data[0]
        m.month = arr_data[1]
        m.date = arr_data[2]
      }
      result = moment(arr_data).format($.dateFormatMonth())
    }
    return result
  },

  java8ParseDateFormatFullMonth: function (arr_data) {
    var result = '',
      m = {}
    if (arr_data) {
      try {
        arr_data[1]-- //months in moment are 0-indexed, but in Java 8 they are 1-indexed
        if (arr_data[6]) {
          delete arr_data[6]
        }
      } catch (ex) {
        console.log(ex.message)
      }
      if (arr_data) {
        m.year = arr_data[0]
        m.month = arr_data[1]
        m.date = arr_data[2]
      }
      result = moment(arr_data).format($.dateFormat())
    }
    return result
  },

  java8ParseDateToLocal: function (arr_data) {
    var result = ''
    if (arr_data) {
      try {
        arr_data[1]-- //months in moment are 0-indexed, but in Java 8 they are 1-indexed
        if (arr_data[6]) {
          delete arr_data[6]
        }
      } catch (ex) {
        console.log(ex.message)
      }
      result = moment.utc(arr_data).local().format($.dateTimeFormat())
    }
    return result
  },

  java8ParseDateToLocalDate: function (arr_data) {
    var result = ''
    if (arr_data) {
      try {
        arr_data[1]-- //months in moment are 0-indexed, but in Java 8 they are 1-indexed
        if (arr_data[6]) {
          delete arr_data[6]
        }
      } catch (ex) {
        console.log(ex.message)
      }
      result = moment.utc(arr_data).local().format($.dateFormat())
    }
    return result
  },

  java8ParseDateToLocalDateClean: function (arr_data) {
    var result = ''
    if (arr_data) {
      try {
        arr_data[1]-- //months in moment are 0-indexed, but in Java 8 they are 1-indexed
        if (arr_data[6]) {
          delete arr_data[6]
        }
      } catch (ex) {
        console.log(ex.message)
      }
      result = moment.utc(arr_data).local().format($.dateFormatClean())
    }
    return result
  },

  java8ParseDateToLocalDateReports: function (arr_data) {
    var result = ''
    if (arr_data) {
      try {
        arr_data[1]-- //months in moment are 0-indexed, but in Java 8 they are 1-indexed
        if (arr_data[6]) {
          delete arr_data[6]
        }
      } catch (ex) {
        console.log(ex.message)
      }
      result = moment.utc(arr_data).format($.dateFormatReports())
    }
    return result
  },

  formatMaturity: function (str) {
    if (str !== '') return moment(str).format('YYYY-MM-DD')
  },

  java8RawTime: function (str) {
    var m,
      result = []

    if (!str) {
      return result
    }

    m = moment(str, 'HH:mm')

    result[0] = m.get('hours')
    result[1] = m.get('minutes')

    return result
  },

  java8RawTimeToUtc: function (str) {
    var m,
      result = []

    if (!str) {
      return result
    }

    m = moment(str, 'HH:mm').utc()

    result[0] = m.get('hours')
    result[1] = m.get('minutes')

    return result
  },

  java8RawDateToUtc: function (str) {
    var m,
      result = []

    if (!str) {
      return result
    }

    m = moment(str, 'DD MMM YYYY HH:mm:ss').utc()

    result[0] = m.get('year')
    result[1] = m.get('month')
    result[2] = m.get('date')
    result[3] = m.get('hours')
    result[4] = m.get('minutes')

    return result
  },

  isTime: function (str) {
    return /^([01]?\d|2[0-3]):([0-5]\d)$/.test(str)
  },

  isNumWithMaxThreeDigits: function (num, point, length) {
    if (parseFloat(num)) {
      var decPart = (num + '').split('.')[point]
      if (!decPart) {
        return true
      }
      return decPart.length > length ? false : true
    } else if (num === '' || parseInt(num) === 0) {
      return true
    } else return false
  },

  lengthNumberGreaterThan3: function (str) {
    var intValid, floatValid
    intValid = $.isNumWithMaxThreeDigits(str, 0, 3)
    floatValid = $.isNumWithMaxThreeDigits(str, 1, 6)
    return intValid && floatValid
  },

  parseAmountToM: function (amount, shouldAbbreviateAmounts) {
    var result, bigNumberAmount
    if ((amount + '').indexOf('M') > 0) return amount
    if (amount >= 1000000 && shouldAbbreviateAmounts) {
      result = $.number2AmountStr(amount)
    } else if (amount < 1) {
      result = '0M'
    } else {
      result = amount
      if (shouldAbbreviateAmounts && amount) {
        bigNumberAmount = new BigNumber(amount)
        result = bigNumberAmount.dividedBy(1000000).toNumber().toString()
        result = $.formatNumber(result.toString()) + 'M'
      }
    }

    return result
  },

  getAmountInt: function (amount) {
    var result
    if ($.isInt(amount) || $.isNu(amount)) return amount
    result = amount.split('M')
    return result[0] * 1000000
  },

  getAmountIntM: function (amount) {
    var result
    if (amount === null) return amount
    if ($.isInt(amount) || $.isNu(amount)) return amount
    result = amount.split('M')
    return result[0]
  },

  //basket should be in M and SS not
  //method found here since it's cross platform (bad solution)
  getFormattedAmount: function (shouldAbbreviateAmounts, amount) {
    if (!isNaN(parseFloat(amount))) {
      return shouldAbbreviateAmounts
        ? $.parseAmountToM(amount, shouldAbbreviateAmounts)
        : $.formatNumber(amount)
    }
    return amount
  },

  // manipulate shown data for single stock to NOT show decimals, only in the tooltip
  manipulateShowedAmountForBond: function (shouldAbbreviateAmounts, amount) {
    var resultAmount = $.getFormattedAmount(shouldAbbreviateAmounts, amount),
      amountIncludes = amount ? amount.toString().includes('(') : null
    if (amount === '-' || amountIncludes) return amount
    return shouldAbbreviateAmounts
      ? resultAmount
      : $.formatNumber(Math.trunc($.amountStr2Number(resultAmount)))
  },

  fixMillionAmount: function (amount, isSingleBond) {
    var amountArr, bigNumberAmount
    BigNumber.config({ ERRORS: false })
    if (amount === undefined || amount === null) return amount
    if (amount.toString().indexOf('M') !== -1) {
      amountArr = amount.split('M')
      bigNumberAmount = new BigNumber($.amountStr2Number(amountArr[0]))
      return (
        $.formatNumber(
          bigNumberAmount.isInteger() || !isSingleBond
            ? bigNumberAmount.toNumber()
            : bigNumberAmount.toFixed(2)
        ) + 'M'
      )
    }
    bigNumberAmount = new BigNumber($.amountStr2Number(amount))
    return $.formatNumber(
      bigNumberAmount.isInteger() || !isSingleBond
        ? bigNumberAmount.toNumber()
        : bigNumberAmount.toFixed(2)
    )
  },

  isAlphaNumeric: (str) => {
    // faster than regex
    let code, i, len

    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i)
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        return false
      }
    }
    return true
  },

  getAbbreviatedAmount: function (amount, shouldAbbreviateAmounts, forceShow) {
    var result, bigNumberAmount
    if (amount !== undefined) {
      amount = Math.trunc(amount)
      if (amount < 1) {
        result = shouldAbbreviateAmounts ? '0M' : '0'
      } else if (amount < 1000000) {
        result = shouldAbbreviateAmounts ? '0M' : $.formatNumber(amount)
      } else if (
        amount >= 1000000000 &&
        forceShow &&
        !shouldAbbreviateAmounts
      ) {
        result = $.formatNumber(amount)
      } else {
        bigNumberAmount = new BigNumber(amount)
        if (shouldAbbreviateAmounts || amount >= 1000000000) {
          if (forceShow) {
            result =
              parseFloat(
                bigNumberAmount.dividedBy(1000000).toFixed(6, 1)
              ).toLocaleString('en-US', { minimumSignificantDigits: 1 }) + 'M'
          } else {
            result =
              parseInt(
                bigNumberAmount.dividedBy(1000000).toFixed(0, 1)
              ).toLocaleString() + 'M'
          }
        } else {
          result = $.formatNumber(amount)
        }
      }
    }
    return result
  },
})

let scrollBarWidth = 0
$.extend($, {
  getScrollBarWidth: () => {
    var $textarea1, $textarea2, $div
    if (!scrollBarWidth) {
      if ($.browser.msie) {
        $textarea1 = $('<textarea cols="10" rows="2"></textarea>')
          .css({
            position: 'absolute',
            top: -1000,
            left: -1000,
          })
          .appendTo('body')
        $textarea2 = $(
          '<textarea cols="10" rows="2" style="overflow: hidden;"></textarea>'
        )
          .css({
            position: 'absolute',
            top: -1000,
            left: -1000,
          })
          .appendTo('body')
        scrollBarWidth = $textarea1.width() - $textarea2.width()
        $textarea1.add($textarea2).remove()
      } else {
        $div = $('<div />')
          .css({
            width: 100,
            height: 100,
            overflow: 'auto',
            position: 'absolute',
            top: -1000,
            left: -1000,
          })
          .prependTo('body')
          .append('<div />')
          .find('div')
          .css({
            width: '100%',
            height: 200,
          })
        scrollBarWidth = 100 - $div.width()
        $div.parent().remove()
      }
    }
    return scrollBarWidth
  },
})

$.extend({ isWindowActive: true })
  ; (function () {
    function onBlur() {
      $.isWindowActive = false
    }

    function onFocus() {
      $.isWindowActive = true
    }

    window.onfocus = onFocus
    window.onblur = onBlur
  })()

$.extend($, {
  loadCss: (css, ctrlName, className = 'css') => {
    const getStyleElem = () => {
      const $head = $('head')
      let $style = $head.find(`style.${className}`)

      if (!$style.length) {
        $style = $(
          `<style type="text/css" class="${className}"></style>`
        ).appendTo($head)
      }

      return $style
    }

    getStyleElem().append(css)
  },
})

let browserType = null
$.extend($, {
  browserType: () => {
    if (browserType === null) {
      if (
        navigator.userAgent.indexOf('iPhone') ||
        navigator.userAgent.indexOf('iPod')
      ) {
        browserType = 'iphone'
      } else if (navigator.userAgent.indexOf('iPad')) {
        browserType = 'ipad'
      } else if (navigator.userAgent.indexOf('Android')) {
        browserType = 'android'
      } else {
        browserType = 'pc'
      }
    }
    return browserType
  },
})

$.fn.extend({
  // Simple wrapper around jQuery animate to simplify animating progress from your app
  // Inputs: Progress as a percent, Callback
  animateProgress: function (progress) {
    return this.each(function () {
      $(this)
        .stop()
        .animate(
          {
            width: progress + '%',
          },
          {
            duration: 1000,

            // swing or linear
            easing: 'swing',

            // this gets called every step of the animation, and updates the label
            step: function (progress) {
              var labelEl = $('.ui-label', this),
                valueEl = $('.ui-label-value', labelEl)

              if (
                Math.ceil(progress) < 15 &&
                $('.ui-label', this).is(':visible')
              ) {
                labelEl.hide()
              } else {
                if (labelEl.is(':hidden')) {
                  labelEl.fadeIn()
                }
              }

              if (Math.ceil(progress) == 100) {
                labelEl.text('Completed')
                setTimeout(function () {
                  labelEl.fadeOut()
                }, 1000)
              } else {
                valueEl.text(Math.ceil(progress) + '%')
              }
            },
          }
        )
    })
  },

  equals: function (compareTo) {
    var i
    if (!compareTo || !compareTo.length || this.length != compareTo.length) {
      return false
    }
    for (i = 0; i < this.length; i++) {
      if (this[i] !== compareTo[i]) {
        return false
      }
    }
    return true
  },

  applyNumberAutoFormat: function (func) {
    // Using val for inputs, and text for content-editable
    if (func !== 'text') func = 'val'
    for (var i = 0; i < this.length; i++) {
      $(this[i])
        .focusin(function () {
          $(this)[func]($.deFormatNumberValues($(this)[func]()))
        })
        .focusout(function () {
          var $this = $(this)

          if ($this.data('shouldTriggerChange')) {
            $this.data('shouldTriggerChange', false)
            $this.change()
          }

          $this[func]($.formatNumberValues($this[func]()))
        })
    }
    return this
  },

  applyAmountStrAutoFormat: function (func) {
    // Using val for inputs, and text for content-editable
    if (func !== 'text') func = 'val'
    for (var i = 0; i < this.length; i++) {
      $(this[i])
        .focusin(function () {
          $(this)[func]($.amountStr2Number($(this)[func]()))
        })
        .focusout(function () {
          var $this = $(this)
          $this[func]($.number2AmountStr($this[func]()))
        })
    }
    return this
  },

  hasScrollBar: function () {
    return this.get(0).scrollHeight > this.height()
  },

  applyAmountAutoFormat: function (func, allowDot) {
    var i, $this

    if (func !== 'text') func = 'val'

    function keyPress($this, e) {
      if (
        (e.which < 48 || e.which > 57) &&
        e.which != 109 &&
        e.which != 107 &&
        e.which != 75 &&
        e.which != 1510 &&
        e.which != 1500 &&
        e.which != 77 &&
        !(allowDot && e.which == 46)
      ) {
        e.preventDefault()
      } else {
        setTimeout(function () {
          var maxLength = $this.attr('maxlength'),
            origNewValue = $this[func](),
            newValue = $.amountStr2Number(origNewValue)
          if (!$.isNumber(origNewValue) || +origNewValue != newValue) {
            $this[func]($.amountStr2Number($this[func]()))
            $this.data('shouldTriggerChange', true)
          }
          if (maxLength && $this[func]().length > maxLength) {
            $this[func]($this[func]().substr(0, maxLength))
          }
        }, 1)
      }
    }

    for (i = 0; i < this.length; i++) {
      $this = $(this[i])
      $this.applyNumberAutoFormat(func).keypress($.proxy(keyPress, this, $this))
    }
    return this
  },

  applyAmountAutoFormatAllowMinus: function (func, allowDot) {
    var i, $this

    if (func !== 'text') func = 'val'

    function keyPress($this, e) {
      if (
        (e.which < 48 || e.which > 57) &&
        e.which != 109 &&
        e.which != 107 &&
        e.which != 1510 &&
        e.which != 1500 &&
        e.which != 75 &&
        e.which != 77 &&
        e.which != 45 &&
        !(allowDot && e.which == 46)
      ) {
        e.preventDefault()
      } else {
        setTimeout(function () {
          var maxLength = $this.attr('maxlength'),
            origNewValue = $this[func](),
            newValue = $.amountStr2Number(origNewValue)
          if (origNewValue !== '-') {
            if (!$.isNumber(origNewValue) || +origNewValue != newValue) {
              $this[func]($.amountStr2Number($this[func]()))
              $this.data('shouldTriggerChange', true)
            }
            if (maxLength && $this[func]().length > maxLength) {
              $this[func]($this[func]().substr(0, maxLength))
            }
          }
        }, 1)
      }
    }

    for (i = 0; i < this.length; i++) {
      $this = $(this[i])
      $this.applyNumberAutoFormat(func).keypress($.proxy(keyPress, this, $this))
    }
    return this
  },

  sillyAutoFormat: function (func) {
    var i, $this

    if (func !== 'text') func = 'val'

    function keyPress($this, e) {
      if (
        (e.which < 48 || e.which > 57) &&
        e.which != 109 &&
        e.which != 107 &&
        e.which != 75 &&
        e.which != 1510 &&
        e.which != 1500 &&
        e.which != 77 &&
        e.which != 45
      ) {
        e.preventDefault()
      } else {
        setTimeout(function () {
          try {
            $this[func](
              $this[func]()
                .replace('M', '000000')
                .replace('m', '000000')
                .replace('K', '000')
                .replace('k', '000')
            )
          } catch (e) { }
        }, 1)
      }
    }

    for (i = 0; i < this.length; i++) {
      $this = $(this[i])
      $this.applyNumberAutoFormat(func).keypress($.proxy(keyPress, this, $this))
    }
    return this
  },

  applyAutoNumberKeyPress: function (allowDot) {
    var i, $this

    function keyPress($this, e) {
      if ((e.which < 48 || e.which > 57) && allowDot && e.which !== 46) {
        e.preventDefault()
      } else {
        setTimeout(function () {
          var maxLength = $this.attr('maxlength'),
            newValue = $this.val()

          if (maxLength && $this.val().length > maxLength) {
            $this.val($this.val().substr(0, maxLength))
          }
        }, 1)
      }
    }

    for (i = 0; i < this.length; i++) {
      $this = $(this[i])
      $this.applyNumberAutoFormat().keypress($.proxy(keyPress, this, $this))
    }
    return this
  },

  applyAutoNumberAllowMinusKeyPress: function (allowDot) {
    var i, $this

    function keyPress($this, e) {
      if (
        (e.which < 48 || e.which > 57) &&
        e.which !== 45 &&
        allowDot &&
        e.which !== 46
      ) {
        e.preventDefault()
      } else {
        setTimeout(function () {
          var maxLength = $this.attr('maxlength'),
            newValue = $this.val()

          if (maxLength && $this.val().length > maxLength) {
            $this.val($this.val().substr(0, maxLength))
          }
        }, 1)
      }
    }

    for (i = 0; i < this.length; i++) {
      $this = $(this[i])
      $this.applyNumberAutoFormat().keypress($.proxy(keyPress, this, $this))
    }
    return this
  },

  applyAutoNumberAllowDotKeyPress: function () {
    var i, $this

    function keyPress($this, e) {
      if ((e.which < 48 || e.which > 57) && e.which !== 46) {
        e.preventDefault()
      } else {
        setTimeout(function () {
          var maxLength = $this.attr('maxlength'),
            newValue = $this.val()

          if (maxLength && $this.val().length > maxLength) {
            $this.val($this.val().substr(0, maxLength))
          }
        }, 1)
      }
    }

    for (i = 0; i < this.length; i++) {
      $this = $(this[i])
      $this.applyNumberAutoFormat().keypress($.proxy(keyPress, this, $this))
    }
    return this
  },

  applyAutoNumberAllowDotAndMinusKeyPress: function () {
    var i, $this

    function keyPress($this, e) {
      if ((e.which < 48 || e.which > 57) && e.which !== 45 && e.which !== 46) {
        e.preventDefault()
      } else {
        setTimeout(function () {
          var maxLength = $this.attr('maxlength'),
            newValue = $this.val()

          if (maxLength && $this.val().length > maxLength) {
            $this.val($this.val().substr(0, maxLength))
          }
        }, 1)
      }
    }

    for (i = 0; i < this.length; i++) {
      $this = $(this[i])
      $this.applyNumberAutoFormat().keypress($.proxy(keyPress, this, $this))
    }
    return this
  },

  cssPrefix: function (property, value) {
    if (property === 'display') {
      this.css(property, '-webkit-' + value)
      this.css(property, value)
    } else {
      this.css('-webkit-' + property, value)
      this.css(property, value)
    }
  },

  selectTextOnFocus: function () {
    var elem, i

    for (i = 0; i < this.length; i++) {
      elem = this[i]
      elem.addEventListener(
        'focus',
        function () {
          elem.setSelectionRange(0, elem.value.length)
        },
        false
      )
    }
    return this
  },

  allowOnlyInts: function () {
    var i,
      f = function (e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
          e.preventDefault()
        }
      }

    for (i = 0; i < this.size(); i++) {
      $(this[i]).keypress(f)
    }
  },

  taMask: function (re, alertFunc) {
    $(this).each(function () {
      $(this).keypress(function (e) {
        var elem = e.target,
          value = e.target.value,
          start = elem.selectionStart,
          end = elem.selectionEnd,
          newVal = '',
          regExpOutput = ''

        newVal += value.substring(0, start)
        newVal += String.fromCharCode(e.which)
        newVal += value.substring(end)

        try {
          regExpOutput = re.exec(newVal)[0]
        } catch (e) { }

        if (newVal !== '' && newVal !== regExpOutput) {
          e.preventDefault()
          if (alertFunc) {
            alertFunc(elem)
          }
        }
      })
    })

    return this
  },

  allowOnlyInts: function () {
    var i,
      f = function (e) {
        if (e.keyCode < 48 || e.keyCode > 57) {
          e.preventDefault()
        }
      }

    for (i = 0; i < this.size(); i++) {
      $(this[i]).keypress(f)
    }
  },

  applyAmountShortcuts: function () {
    var i,
      f = function (e) {
        if (e.target.value && e.target.value.slice(-1) !== '.') {
          e.target.value = $.amountStr2Number(e.target.value)
        }
      }

    for (i = 0; i < this.size(); i++) {
      $(this[i]).keyup(f)
    }
  },
})
