import './controls/utils/jquery.extend'
import './controls/utils/string.extend'
import './controls/utils/backbone.shim'
import './controls/utils/jqueryui.shim'

import './controls/wsng/wsng'
import './controls/cacheLoaderPopup/cacheLoaderPopup'

import app from './controls/head/head-loginElixium'

app.initialize()
