define([
    "Underscore",
    "jQuery",
    "Backbone",
    "ws"
], function (_, $, Backbone, oWs) {
    "use strict";
    var ParametersHolder = Backbone.Model.extend({
        _parameters: null,
        _ccPairId: null,
        _b2bWidgetGroupId: null,
        _streamingProductTypeId: null,
        _currenciesOptions: null,
        _ordersCounterTime: null,
        _ordersCounterStatus: null,
        _ccPairById: null,
        _ccPairNames: null,
        _ccPairByIdAndPlatform: null,
        _popUpDynamicMarket: null,
        _streamerPlatform: null,
        _streamingCurrency: null, // AKA selectedCurrency
        _clients: null,
        _clientsById: null,
        _colorBuy: null,
        _colorSell: null,
        _terminologyBuy: null,
        _terminologySell: null,
        _showSpreadInWidgets: null,
        _marketChangeWarning: null,
        _marketChangeWarningInterval: null,
        _disableAmountButtonInMarket: null,
        _isShowOnlyOwnData: false,
        _isQuickLimitMarketMaking: false,
        _isExtendedMarketTitle: false,
        _theme: null,
        _themes: null,
        _themeId: null,
        _hasKeyboardSupport: null,
        _hasParameters: false,
        _quickLimitShortcutCcPairStr: null,
        _isMarketBlink: null,
        _isFlipAlgoOnOrderAdd: null,
        _isSinglePlatform: true,
        _defaultOrderTypeTimeInForce: null,
        _defaultOrderTypeTimeInForceQL: null,
        _defaultOrderTypeTimeInForceOrg: null,
        _defaultOrderTypeTimeInForceQLOrg: null,
        _allAvailTifs: null,
        _b2bCcPairId: null,
        _platformUser: null,
        _showFullPriceInBook: null,
        _supportIw: null,
        _showPlatformInOrderDashboard: null,
        _streamList: null,
        _timeout: null,
        _timeoutSound: null,
        _isTimeoutActive: null,
        _aggregations: null,
        _benchmark: null,
        _termMap: null,
        _showFullPriceOnAgg: null,
        _ccPairSha512: null,
        _showPoweredByTradAir: null,
        _isinLinkPrefix: null,
        _systemCurrencies: null,
        _maturityTerms: null,
        _systemTerms: null,
        _benchmarks: null,
        _isFirstLoad: null,
        _sortedSystemCurrencies: null,
        _sortedSettlementMethods: null,
        _sortedStartDate: null,
        _sortedBaskets: null,
        _sortedMaturity: null,


        initialize: function () {
            this._ccPairById = [];
            this._ccPairNames = {};
            this._ccPairByIdAndPlatform = {};
            this._clientsById = {};
            this._allAvailTifs = [];
            this._isFirstLoad = true;
            this._types = [{
                value: "SINGLE_BOND",
                text: "Single Stock",
                fieldName: "basketType"
            },{
                value: "BASKET",
                text: "Basket",
                fieldName: "basketType"
            }];

            window.marketSrcFormat = $.proxy(this.marketSrcFormat, this);

            _.bindAll(this, "_handleParametersCallback", "_handleParametersPushCallback")
        },

        getParameters: function () {
            oWs.subscribe("/topic/system.parameterHolder", this._handleParametersPushCallback);
            return oWs.sendMessage("/getParametersByUserId")
                .then(this._handleParametersCallback);
        },

        _handleParametersPushCallback: function (data) {
            var data = JSON.parse(data.body);
            this._handleParametersCallback(data);
        },

        types: function() {
            return this._types;    
        },
        
        ccPairId: function () {
            return this._ccPairId;
        },

        maturityTerms: function () { //maturity
            return _.filter(this._maturityTerms, function(maturityOption) {
                return maturityOption.dateFilterType === "TERM";
            });
        },

        systemTerms: function () { //startdate
            return _.filter(this._systemTerms, function(startDateOption) {
                return startDateOption.dateFilterType === "TERM";
            });
        },

        startDateFilters: function () {
            return this._systemTerms;
        },

        maturityFilters: function () {
            return this._maturityTerms;
        },

        settlementMethods: function () { //agent
            return this._benchmarks;
        },

        isinLinkPrefix: function () {
            return this._isinLinkPrefix;
        },

        currenciesOptions: function () {
            return this._currenciesOptions;
        },

        ccPairById: function () {
            return this._ccPairById;
        },

        ccPairNames: function () {
            return this._ccPairNames;
        },

        sortedSystemCurrency: function () {
            this._sortedSystemCurrencies = _(this._systemCurrencies).sortBy("name");
            return this._sortedSystemCurrencies;
        },

        sortedBaskets: function () {
            this._sortedBaskets = _(this._ccPairs).sortBy("name");
            return this._sortedBaskets;
        },

        sortedSettlementMethods: function () {
            this._sortedSettlementMethods = _(this._benchmarks).sortBy("name");
            return this._sortedSettlementMethods;
        },

        sortedStartDates: function () {
            this._sortedStartDate =  _(this._systemTerms).sortBy(
                function(startDate) {
                    return [startDate.type, startDate.orderBy];
                }
            );
            return this._sortedStartDate;
        },

        sortedMaturities: function() {
            this._sortedMaturity = _(this._maturityTerms).sortBy("orderBy");
            return this._sortedMaturity;
        },

        ccPairIdsList: function () {
            return _.map(_.keys(this._ccPairNames), function (val) {
                return +val;
            });
        },

        popUpDynamicMarket: function () {
            return this._popUpDynamicMarket;
        },

        streamerPlatform: function () {
            return this._streamerPlatform;
        },

        ccPairByIdAndPlatform: function (ccPairId, platformName) {
            var result = this._ccPairByIdAndPlatform;

            if (typeof ccPairId !== "undefined" && typeof platformName !== "undefined") {
                result = this._ccPairByIdAndPlatform[ccPairId + "-" + platformName];
            }

            return result;
        },

        ccPairByIdAndPlatformStr: function (str) {
            return this._ccPairByIdAndPlatform[str];
        },

        marketSrcShortName: function (str) {
            var i, result = null;

            if (this._ccPairByIdAndPlatform) {
                for (i in this._ccPairByIdAndPlatform) {
                    if (this._ccPairByIdAndPlatform.hasOwnProperty(i)) {
                        if (this._ccPairByIdAndPlatform[i].marketSrc === str) {
                            result = this._ccPairByIdAndPlatform[i].marketSrcShortName;
                            break;
                        }
                    }
                }
            }

            return result;
        },

        marketSrcFormat: _.memoize(function (str) {
            var result = this.marketSrcShortName(str);

            if (str && (result === null || result === "")) {
                result = str;
            }

            if (result) {
                result = result.substring(0, 4).toUpperCase();

                // If AGG, and has more than one platform
                // we don't want to show anything at all
                if (result.indexOf(",") !== -1) {
                    //result = result.replace(",", "+");
                    result = "";
                }
                else if (str && str.indexOf(",") !== -1) {
                    //result += "+";
                    result = "";
                }
            }

            return result;
        }),

        getCcPairByName: function (name) {
            var result;

            for (var property in this._ccPairByIdAndPlatform) {
                if (this._ccPairByIdAndPlatform.hasOwnProperty(property)) {
                    var ccPairByIdAndPlatform = this._ccPairByIdAndPlatform[property];
                    if (name === ccPairByIdAndPlatform.name) {
                        result = ccPairByIdAndPlatform;
                    }
                }
            }

            return result;
        },

        getCcPairIdByName: function (name) {
            var result = null,
                ccPair = this.getCcPairByName(name);

            if (ccPair) {
                result = ccPair.ccPairId;
            }

            return result;
        },

        getDigitsDisplay: function (ccPair, platform, productType) {
            var ccPairId = +ccPair,
                result,
                field = "digitsDisplay",
                ccPairResult;

            if (isNaN(ccPairId)) {
                ccPairId = this.getCcPairIdByName(ccPair);
            }

            ccPairResult = this.ccPairByIdAndPlatform(ccPairId, $.removeDriverAttr(platform));

            if ($.isForwardOrSwap(productType)) {
                field = "rfsDigitsDisplay";
            }

            if (ccPairResult) {
                result = ccPairResult[field];
            }

            return result || 5;
        },

        streamingCurrency: function () {
            return this._streamingCurrency;
        },

        clients: function () {
            return this._clients;
        },

        clientById: function (id) {
            return this._clientsById[id];
        },

        rfqFwdAmountThreshold: function () {
            return this._parameters.rfqFwdAmountThreshold;
        },

        rfqSpotAmountThreshold: function () {
            return this._parameters.rfqSpotAmountThreshold;
        },

        rfqSwapAmountThreshold: function () {
            return this._parameters.rfqSwapAmountThreshold;
        },

        colorBuy: function () {
            return this._colorBuy;
        },

        colorSell: function () {
            return this._colorSell;
        },

        terminologyBuy: function () {
            return this._terminologyBuy;
        },

        terminologySell: function () {
            return this._terminologySell;
        },

        showSpreadInWidgets: function () {
            return this._showSpreadInWidgets;
        },

        marketChangeWarning: function () {
            return this._marketChangeWarning;
        },

        marketChangeWarningInterval: function () {
            return this._marketChangeWarningInterval;
        },

        disableAmountButtonInMarket: function () {
            return this._disableAmountButtonInMarket;
        },

        isShowOnlyOwnData: function () {
            return this._isShowOnlyOwnData;
        },

        isQuickLimitMarketMaking: function () {
            return this._isQuickLimitMarketMaking && !$.isUserOfClientOrg();
        },

        isMarketBlink: function () {
            return this._isMarketBlink;
        },

        isFlipAlgoOnOrderAdd: function () {
            return this._isFlipAlgoOnOrderAdd;
        },

        isExtendedMarketTitle: function () {
            return this._isExtendedMarketTitle;
        },

        theme: function () {
            return this._theme;
        },

        themes: function () {
            return this._themes;
        },

        themeId: function () {
            return this._themeId;
        },

        hasKeyboardSupport: function () {
            return this._hasKeyboardSupport;
        },

        showFullPriceInBook: function () {
            return this._showFullPriceInBook;
        },

        supportIw: function () {
            return this._supportIw;
        },

        showPlatformInOrderDashboard: function () {
            return this._showPlatformInOrderDashboard;
        },

        hasParameters: function () {
            return this._hasParameters;
        },

        isSinglePlatformOrg: function () {
            return this._isSinglePlatform;
        },

        b2bCcPairId: function () {
            return this._b2bCcPairId;
        },

        showFullPriceOnAgg: function () {
            return this._showFullPriceOnAgg;
        },

        showPoweredByTradAir: function () {
            return this._showPoweredByTradAir;
        },

        getDefaultMarketInputValue: function (ccPair, amount, weightUnit) {
            var result;

            if (ccPair && typeof ccPair.name === "string" && ccPair.name.match(/xag|xau/ig)) {
                if ($.stringEqualsIgnoreCase(weightUnit, "oz")) {
                    result = Math.max(100, ccPair.minSize);
                }
                else {
                    result = Math.max(3, ccPair.minSize);
                }
            } else {
                result = amount || 1000000;
            }

            return result;
        },

        getACcPair: function () {
            var i, minIndex = 0, result = null;

            if (this._currenciesOptions instanceof Array) {
                for (i = 0; i < this._currenciesOptions.length; i++) {
                    if (this._currenciesOptions[i].ccPairId < this._currenciesOptions[minIndex].ccPairId) {
                        minIndex = i;
                    }
                }

                result = this._currenciesOptions[minIndex];
            }

            return result;
        },

        getACcPairId: function () {
            var result = null,
                ccPair = this.getACcPair();

            if (ccPair && ccPair.ccPairId) {
                result = ccPair.ccPairId;
            }

            return result;
        },

        getACcPairById: function (ccPairId) {
            var i, result = null;

            if (this._currenciesOptions instanceof Array) {
                for (i = 0; i < this._currenciesOptions.length; i++) {
                    if (this._currenciesOptions[i].ccPairId == ccPairId) {
                        result = this._currenciesOptions[i];
                        break;
                    }
                }
            }

            return result;
        },

        getB2bWidgetGroupId: function () {
            return this._b2bWidgetGroupId;
        },

        setB2bWidgetGroupId: function (value) {
            this._b2bWidgetGroupId = value;
        },

        platformUser: function () {
            return this._platformUser;
        },

        allAvailTifs: function () {
            return this._allAvailTifs;
        },

        timeout: function () {
            return this._timeout;
        },

        timeoutSound: function () {
            return this._timeoutSound;
        },

        benchmark: function () {
            return this._benchmark;
        },

        isTimeoutActive: function () {
            return this._isTimeoutActive;
        },

        accounts: function () {
            return this._accounts;
        },

        baskets: function () {
            return this._ccPairs;
        },

        systemCurrencies: function () {
            return this._systemCurrencies;
        },

        getBasketById: function (basketId) {
            return _.chain(this.baskets())
                .filter(function (obj) {
                    return obj.id === basketId
                })
                .first()
                .value();
        },

        getStartDateById: function (startDateId) {
            return _.chain(this.systemTerms())
                .filter(function (obj) {
                    return obj.id === startDateId
                })
                .first()
                .value();
        },

        getMaturityById: function (maturityId) {
            return _.chain(this.maturityTerms())
                .filter(function (obj) {
                    return obj.id === maturityId
                })
                .first()
                .value();
        },

        getCurrencyById: function (currencyId) {
            return _.chain(this._systemCurrencies)
                .filter(function (obj) {
                    return obj.id === currencyId;
                })
                .first()
                .value();
        },

        getsettlementMethodById: function (settlementMethodId) {
            return _.chain(this.settlementMethods())
                .filter(function (obj) {
                    return obj.id === settlementMethodId;
                })
                .first()
                .value();
        },

        defaultTif: function (val) {
            var result = this._defaultOrderTypeTimeInForce;

            if (typeof val !== "undefined") {
                this._defaultOrderTypeTimeInForce = val;
                result = this;
            }
            else if (typeof result !== "undefined" && this._allAvailTifs instanceof Array
                && this._allAvailTifs.indexOf(result) === -1 && this._defaultOrderTypeTimeInForceOrg) {
                result = this._defaultOrderTypeTimeInForceOrg;
            }

            return result;
        },

        defaultTifQL: function (val) {
            var result = this._defaultOrderTypeTimeInForceQL;

            if (typeof val !== "undefined") {
                this._defaultOrderTypeTimeInForceQL = val;
                result = this;
            }
            else if (typeof result !== "undefined" && this._allAvailTifs instanceof Array
                && this._allAvailTifs.indexOf(result) === -1 && this._defaultOrderTypeTimeInForceQLOrg) {
                result = this._defaultOrderTypeTimeInForceQLOrg;
            }

            return result;
        },

        quickLimitShortcutCcPair: function (ccPairStr) {
            var result = this.ccPairByIdAndPlatformStr(this._quickLimitShortcutCcPairStr);

            if (typeof ccPairStr !== "undefined") {
                this._quickLimitShortcutCcPairStr = ccPairStr;
            }

            return result;
        },

        currencyListClient: function (clientOrgId) {
            var client = this.clientById(clientOrgId),
                clientCurrencies = client.platformsCcPairMap,
                currencies = {}, i, index, ccPair;

            for (i in clientCurrencies) {
                if (clientCurrencies.hasOwnProperty(i)) {
                    index = i + "-" + clientCurrencies[i];
                    ccPair = this.ccPairByIdAndPlatformStr(index);
                    if (ccPair) {
                        currencies[index] = ccPair;
                    }
                }
            }
            // Get all available ccPairs as AutoComplete option array
            return currencies;
        },

        availableCcPairsAsAcArr: function (clientOrgId) {
            var i, value, text, alt = "", list = this._ccPairByIdAndPlatform,
                result = [JSON.stringify({value: "-1", text: "--- / ---"})];

            if (clientOrgId) {
                list = this.currencyListClient(clientOrgId);
            }

            // Adding string representation of values to array in order
            // to eliminate duplicates, and returning parsed data

            for (i in list) {
                if (list.hasOwnProperty(i)) {
                    if (list[i] && list[i].ccPairId && list[i].name) {
                        value = list[i].ccPairId;
                        text = list[i].name;

                        // First, remove the spaces, then concat the same
                        // string with slashes removed. For example:
                        // EUR / USD ==> EUR/USDEURUSD. This way a user
                        // can filter currency pairs by the original text
                        // (EUR / USD) or the shorter ways: EUR/USD and
                        // even EURUSD.
                        if (typeof text === "string") {
                            alt = text.replace(/\s+/g, "");
                            alt = alt + alt.replace(/\//g, "");
                        }

                        result.push(JSON.stringify({value: value, text: text, alt: alt}));
                    }
                }
            }

            return _.map(_.uniq(result), function (obj) {
                return JSON.parse(obj);
            })
        },

        availablePlatform: function (ccPairId, clientOrgId) {
            var result = null, arr, client;

            if (clientOrgId) {
                client = this.clientById(clientOrgId);
                result = client.platformsCcPairMap[ccPairId];
            }
            else {
                arr = this.availablePlatformsAsAcArr(ccPairId, false);

                if (arr instanceof Array && arr.length > 0 && typeof arr[0].value !== "undefined") {
                    result = arr[0].value;
                }
            }

            return result;
        },

        availablePlatformsAsAcArr: function (ccPairId, withDefault) {
            var i, name, result = [];

            if (withDefault) {
                result.push(JSON.stringify({value: "", text: "---"}));
            }

            // Get all available platforms as AutoComplete option array (specific ccPair)
            // Adding string representation of values to array in order
            // to eliminate duplicates, and returning parsed data

            for (i in this._ccPairByIdAndPlatform) {
                if (this._ccPairByIdAndPlatform.hasOwnProperty(i)) {
                    if (this._ccPairByIdAndPlatform[i].ccPairId === ccPairId) {
                        name = this._ccPairByIdAndPlatform[i].marketSrc;

                        result.push(JSON.stringify({value: name, text: name}));
                    }

                }
            }

            return _.map(_.uniq(result), function (obj) {
                return JSON.parse(obj);
            })
        },

        availablePlatformsShortNameAsAcArr: function (ccPairId, withDefault) {
            var result = this.availablePlatformsAsAcArr(ccPairId, withDefault);

            result = _.map(result, function (obj) {
                return {value: obj.value, text: this.marketSrcFormat(obj.text)};
            }, this);

            return result;
        },

        availablePlatformsFullSumAsAcArr: function (ccPairId, withDefault, orderType, filterAgg) {
            var result = this.availablePlatformsAsAcArr(ccPairId, withDefault);

            // Filtering only the platforms that should be visible in the
            // fullSum widget, meaning the platforms that "supportFullSum"
            // field is set to true (or platform is Agg, or included in the Agg)
            // *AND* that the ccPair supports the selected TIF

            return _.filter(result, function (obj) {
                var ccPair = this.ccPairByIdAndPlatform(ccPairId, obj.value);

                return this._doesCcPairSupportFullSum(ccPair, filterAgg)
                    && this.tifSupportedForCcPair(ccPair.ccPairId, ccPair.marketSrc, orderType);
            }, this);
        },

        streamList: function () {
            return this._streamList;
        },

        availableTifsAsAcArr: function (ccPairId, platform) {
            var i, arr, result = [], value, text,
                ccPair = this.ccPairByIdAndPlatform(ccPairId, platform);

            if (ccPair && ccPair.platformOrderTypeData instanceof Array) {
                arr = ccPair.platformOrderTypeData;

                for (i = 0; i < arr.length; i++) {
                    value = arr[i];
                    text = value.toUpperCase();
                    result.push({value: value, text: text});
                }
            }

            return result;
        },

        availableTifsCcPairsAsAcArr: function (ccPairId) {
            var filtered = _.filter(this._ccPairByIdAndPlatform, function (obj) {
                return obj.ccPairId === ccPairId
            });

            return this._availableTifsCcPairsAsAcArrByFilter(filtered);
        },

        availableTifsCcPairsFullSumAsAcArr: function (ccPairId, filterAgg) {
            var filtered = _.filter(this._ccPairByIdAndPlatform, function (obj) {
                return obj.ccPairId === ccPairId && this._doesCcPairSupportFullSum(obj, filterAgg)
            }, this);

            return this._availableTifsCcPairsAsAcArrByFilter(filtered);
        },

        defaultTifForCcPairs: function (ccPairId) {
            var arr = this.availableTifsCcPairsAsAcArr(ccPairId),
                result = null;

            if (arr.length > 0) {
                result = arr[0].value;
            }

            return result;
        },

        defaultTifForCcPairsFullSum: function (ccPairId, filterAgg) {
            var arr = this.availableTifsCcPairsFullSumAsAcArr(ccPairId, filterAgg),
                result = null;

            if (arr.length > 0) {
                result = arr[0].value;
            }

            return result;
        },

        defaultTifForCcPair: function (ccPairId, platform) {
            // Default time in force for a specific ccPair,
            // Priorities: 1. User selected default, 2. Organization default,
            // 3. Currency pair default

            var defaultTif = this.defaultTif(),
                ccPair = this.ccPairByIdAndPlatform(ccPairId, platform);

            if (ccPair.platformOrderTypeData && ccPair.platformOrderTypeData.indexOf(defaultTif) === -1) {
                defaultTif = ccPair.defaultOrderTypeTimeInForce;
            }

            return defaultTif;
        },

        tifSupportedForCcPair: function (ccPairId, platform, tif) {
            var result = false,
                ccPair = this.ccPairByIdAndPlatform(ccPairId, platform);

            if (ccPair && ccPair.platformOrderTypeData.indexOf(tif) !== -1) {
                result = true;
            }

            return result;
        },

        tifSupportedForCcPairs: function (ccPairId, tif) {
            var arr = _.pluck(this.availableTifsCcPairsAsAcArr(ccPairId), "value");

            return arr.indexOf(tif) !== -1;
        },

        tifSupportedForCcPairsFullSum: function (ccPairId, tif, filterAgg) {
            var arr = _.pluck(this.availableTifsCcPairsFullSumAsAcArr(ccPairId, filterAgg), "value");

            return arr.indexOf(tif) !== -1;
        },

        leadingCurrency: function (ccPair) {
            var result;

            if (ccPair && ccPair.name) {
                result = ccPair.name.substring(0, 3);
            }

            return result;
        },

        secondaryCurrency: function (ccPair) {
            var result;

            if (ccPair && ccPair.name) {
                result = ccPair.name.substring(6, 9);
            }

            return result;
        },

        termNameByDate: _.memoize(function (ccPairId, termDate) {
            var map = _.invert(this._termMap[ccPairId]);
            return map[termDate];
        }, function (ccPairId, termDate) {
            return ccPairId + "-" + termDate;
        }),

        terms: function (ccPairId) {
            return this._termMap[ccPairId];
        },

        aggComponents: function (ccPairId) {
            return _.clone(this._aggregations[ccPairId]) || [];
        },

        termDateByName: function (ccPairId, termName) {
            return this._termMap[ccPairId][termName];
        },

        hasCustomLogo: function () {
            //return this._customLogo;
            return true;
        },

        _updateParameters: function (data) {
            _.each(data, function (value, index) {
                if (this._parameters[index] != undefined && this._parameters[index] != null && value) {
                    this._parameters[index] = value;
                }
            }, this)
        },

        _handleParametersCallback: function (data) {
            var i,
                j,
                k,
                isChanged = false,
                index,
                ccPairArr,
                ccPair,
                ccPairSha512LocalStorageKey,
                ccPairLocalStorageKey;

            if (data) {
                if (this._isFirstLoad) {
                    this._parameters = data;
                    this._isFirstLoad = false;
                } else {
                    this._updateParameters(data);
                }
                isChanged = true;
            }

            if (data.maturityTerms) {
                this._maturityTerms = data.maturityTerms;
            }

            if (data.systemTerms) {
                this._systemTerms = data.systemTerms;
            }

            if (data.globalParameters) {
                if (data.globalParameters.isinLinkPrefix) {
                    this._isinLinkPrefix = data.globalParameters.isinLinkPrefix;
                }
            }

            if (data.maturityTerms) {
                this._maturityTerms = data.maturityTerms;
                this.trigger("change:maturityTerms");
                isChanged = true;
            }

            if (data.systemTerms) {
                this._systemTerms = data.systemTerms;
                this.trigger("change:systemTerms");
                isChanged = true;
            }

            if (data.settlementMethods) {
                this._settlementMethods = data.settlementMethods;
                this.trigger("change:settlementMethods");
                isChanged = true;
            }

            if (data.platformUser) {
                this._platformUser = data.platformUser;
                this.trigger("change:platformUser");
                isChanged = true;
            }

            if (data.baskets) {
                this._baskets = data.baskets;
                this.trigger("change:baskets");
                isChanged = true;
            }

            if (data.systemCurrencies) {
                this._systemCurrencies = data.systemCurrencies;
                this.trigger("change:systemCurrencies");
                isChanged = true;
            }

            if (data.accounts) {
                this._accounts = data.accounts;
                this.trigger("change:accounts");
                isChanged = true;
            }

            if (data.ccPairId) {
                this._ccPairId = data.ccPairId;
                this.trigger("change:ccPairId");
                isChanged = true;
            }

            if (data.b2bWidgetGroupId) {
                this._b2bWidgetGroupId = data.b2bWidgetGroupId;
                this.trigger("change:b2bWidgetGroupId");
                isChanged = true;
            }

            if (data.streamingProductTypeId) {
                this._streamingProductTypeId = data.streamingProductTypeId;
                this.trigger("change:streamingProductTypeId");
                isChanged = true;
            }

            if (data.ordersCounterTime != null && data.ordersCounterStatus != null) {
                this._ordersCounterTime = data.ordersCounterTime;
                this._ordersCounterStatus = data.ordersCounterStatus;
                this.trigger("change:ordersCounterTime");
                isChanged = true;
            }

            if (data.streamingProductTypeId) {
                this._streamingProductTypeId = data.streamingProductTypeId;
                this.trigger("change:streamingProductTypeId");
                isChanged = true;
            }

            if (data.popUpDynamicMarket) {
                this._popUpDynamicMarket = data.popUpDynamicMarket;
                this.trigger("change:popUpDynamicMarket");
                isChanged = true;
            }

            if (data.streamerPlatform) {
                this._streamerPlatform = data.streamerPlatform;
                this.trigger("change:streamerPlatform");
                isChanged = true;
            }

            if (data.colorBuy) {
                this._colorBuy = data.colorBuy;
                this.trigger("change:colorBuy");
                isChanged = true;
            }

            if (data.colorSell) {
                this._colorSell = data.colorSell;
                this.trigger("change:colorSell");
                isChanged = true;
            }

            if (data.terminologyBuy) {
                this._terminologyBuy = data.terminologyBuy;
                this.trigger("change:terminologyBuy");
                isChanged = true;
            }

            if (data.terminologySell) {
                this._terminologySell = data.terminologySell;
                this.trigger("change:terminologySell");
                isChanged = true;
            }

            if (data.showSpreadInWidgets) {
                this._showSpreadInWidgets = data.showSpreadInWidgets;
                this.trigger("change:showSpreadInWidgets");
                isChanged = true;
            }

            if (data.marketChangeWarning) {
                this._marketChangeWarning = data.marketChangeWarning;
                this.trigger("change:marketChangeWarning");
                isChanged = true;
            }

            if (data.marketChangeWarningInterval) {
                this._marketChangeWarningInterval = data.marketChangeWarningInterval;
                this.trigger("change:marketChangeWarningInterval");
                isChanged = true;
            }

            if (data.disableAmountButtonInMarket) {
                this._disableAmountButtonInMarket = data.disableAmountButtonInMarket;
                this.trigger("change:disableAmountButtonInMarket");
                isChanged = true;
            }

            if (data.showOnlyOwnData) {
                this._isShowOnlyOwnData = data.showOnlyOwnData;
                this.trigger("change:isShowOnlyOwnData");
                isChanged = true;
            }
            if (data.quickLimitMarketMaking) {
                this._isQuickLimitMarketMaking = data.quickLimitMarketMaking;
                this.trigger("change:isQuickLimitMarketMaking");
                isChanged = true;
            }

            if (data.marketBlink) {
                this._isMarketBlink = data.marketBlink;
                this.trigger("change:isMarketBlink");
                isChanged = true;
            }

            if (data.flipAlgoOnOrderAdd) {
                this._isFlipAlgoOnOrderAdd = data.flipAlgoOnOrderAdd;
                this.trigger("change:isFlipAlgoOnOrderAdd");
                isChanged = true;
            }

            if (data.extendedMarketTitle) {
                this._isExtendedMarketTitle = data.extendedMarketTitle;
                this.trigger("change:isExtendedMarketTitle");
                isChanged = true;
            }

            // the order of themesId and themes trigger is important. do not change!
            // also the customLogo boolean

            if (data.hasOwnProperty("customLogo")) {
                this._customLogo = data.customLogo;
                this.trigger("change:customLogo");
                isChanged = true;
            }

            if (data.theme) {
                this._theme = data.theme;
                this.trigger("change:theme");
                isChanged = true;
            }

            if (data.themeId) {
                this._themeId = data.themeId;
                this.trigger("change:themeId");
                isChanged = true;
            }

            if (data.themes) {
                this._themes = data.themes;
                this.trigger("change:themes");
                isChanged = true;
            }

            if (data.timeout) {
                this._timeout = data.timeout;
                this.trigger("change:timeout");
                isChanged = true;
            }

            if (data.timeoutSound) {
                this._timeoutSound = data.timeoutSound;
                this.trigger("change:timeoutSound");
                isChanged = true;
            }

            if (data.benchmark) {
                this._benchmark = data.benchmark;
                this.trigger("change:benchmark");
                isChanged = true;
            }

            if (data.timeoutIsActive) {
                this._isTimeoutActive = data.timeoutIsActive;
                this.trigger("change:isTimeoutActive");
                isChanged = true;
            }

            if (typeof data.hasKeyboardSupport !== "undefined") {
                this._hasKeyboardSupport = data.hasKeyboardSupport;
                this.trigger("change:hasKeyboardSupport");
                isChanged = true;
            }

            if (typeof data.showFullPriceInBook !== "undefined") {
                this._showFullPriceInBook = data.showFullPriceInBook;
                this.trigger("change:showFullPriceInBook");
                isChanged = true;
            }

            if (typeof data.supportIw !== "undefined") {
                this._supportIw = data.supportIw;
                this.trigger("change:supportIw");
                isChanged = true;
            }

            if (typeof data.showPlatformInOrderDashboard !== "undefined") {
                this._showPlatformInOrderDashboard = data.showPlatformInOrderDashboard;
                this.trigger("change:showPlatformInOrderDashboard");
                isChanged = true;
            }

            if (data.quickLimitShortcutCcPairStr) {
                this._quickLimitShortcutCcPairStr = data.quickLimitShortcutCcPairStr;
                this.trigger("change:quickLimitShortcutCcPairStr");
                isChanged = true;
            }

            if (data.b2bCcPairId) {
                this._b2bCcPairId = data.b2bCcPairId;
                this.trigger("change:b2bCcPairId");
                isChanged = true;
            }

            if (data.clients) {
                this._clients = data.clients;

                for (j = 0; j < this._clients.length; j++) {
                    this._clientsById[this._clients[j].clientOrgId] = this._clients[j];
                }

                this.trigger("change:clients");
                isChanged = true;
            }

            if (data.defaultOrderTypeTimeInForceOrg) {
                this._defaultOrderTypeTimeInForceOrg = data.defaultOrderTypeTimeInForceOrg;
                isChanged = true;
            }

            if (data.defaultOrderTypeTimeInForceQLOrg) {
                this._defaultOrderTypeTimeInForceQLOrg = data.defaultOrderTypeTimeInForceQLOrg;
                isChanged = true;
            }

            if (typeof data.defaultOrderTypeTimeInForce !== "undefined") {
                this._defaultOrderTypeTimeInForce = data.defaultOrderTypeTimeInForce;
                isChanged = true;
            }

            if (typeof data.defaultOrderTypeTimeInForceQL !== "undefined") {
                this._defaultOrderTypeTimeInForceQL = data.defaultOrderTypeTimeInForceQL;
                isChanged = true;
            }

            if (typeof data.streamList !== "undefined") {
                this._streamList = data.streamList;
                isChanged = true;
            }

            if (typeof data.termMap !== "undefined") {
                this._termMap = data.termMap;
                isChanged = true;
            }

            if (typeof data.showFullPriceOnAgg !== "undefined") {
                this._showFullPriceOnAgg = data.showFullPriceOnAgg;
                this.trigger("change:showFullPriceOnAgg");
                isChanged = true;
            }

            if (typeof data.showPoweredByTradAir !== "undefined") {
                this._showPoweredByTradAir = data.showPoweredByTradAir;
                isChanged = true;
            }

            ccPair = data.ccPair;
            ccPairSha512LocalStorageKey = "ccPairSha512" + $.getTaLoginFromLocalStorage().userId;
            ccPairLocalStorageKey = "ccPair" + $.getTaLoginFromLocalStorage().userId;

            if (typeof data.ccPairSha512 !== "undefined") {
                this._ccPairSha512 = data.ccPairSha512;

                $.c("ccPair SHA512", localStorage.getItem(ccPairSha512LocalStorageKey), data.ccPairSha512);

                if (localStorage.getItem(ccPairSha512LocalStorageKey) === this._ccPairSha512
                    && (!ccPair || (_.isArray(ccPair) && ccPair.length === 0))) {
                    $.c("ccPair SHA512 equals, restoring from localStorage");
                    ccPair = JSON.parse(localStorage.getItem(ccPairLocalStorageKey));
                }
                else {
                    if (_.isArray(ccPair) && ccPair.length > 0) {
                        $.c("ccPair SHA512 is not equal, storing new data");

                        try {
                            localStorage.setItem(ccPairSha512LocalStorageKey, this._ccPairSha512);
                            localStorage.setItem(ccPairLocalStorageKey, JSON.stringify(ccPair));
                        }
                        catch (e) {
                            $.c("Exception, localStorage is probably full", e);

                            // localStorage is full, thus saving the data failed.
                            // cleaning our previous records (identified by ccPair*)
                            // and re-saving the data
                            this._clearShaFromStorage();

                            localStorage.setItem(ccPairSha512LocalStorageKey, this._ccPairSha512);
                            localStorage.setItem(ccPairLocalStorageKey, JSON.stringify(ccPair));
                        }
                    }
                    else {
                        localStorage.setItem(ccPairSha512LocalStorageKey, null);
                        localStorage.setItem(ccPairLocalStorageKey, null);
                    }

                }

                this.trigger("change:ccPairSha512");
                isChanged = true;
            }

            ccPairArr = this._fixFigures(ccPair);
            if (ccPairArr instanceof Array) {
                this._currenciesOptions = ccPairArr;
                this._aggregations = this._getAggregations(ccPairArr);
                this._ccPairNames = {};

                this._ccPairById.length = 0;
                for (i = 0; i < ccPairArr.length; ++i) {
                    this._ccPairById[ccPairArr[i].ccPairId] = ccPairArr[i];
                    this._ccPairNames[ccPair[i].ccPairId] = ccPair[i].name;
                }

                this._currenciesOptions = ccPairArr;
                this._ccPairByIdAndPlatform = {};
                for (i = 0; i < ccPairArr.length; ++i) {
                    index = ccPairArr[i].ccPairId + "-" + ccPairArr[i].marketSrc;
                    this._ccPairByIdAndPlatform[index] = ccPairArr[i];
                    this._allAvailTifs = _.union(this._allAvailTifs, ccPairArr[i].platformOrderTypeData);

                    if ((i + 1) < ccPairArr.length) {
                        if (ccPairArr[i].marketSrc !== ccPairArr[i + 1].marketSrc) {
                            this._isSinglePlatform = false;
                        }
                    }
                }

                if (this.defaultTif()) {
                    this._allAvailTifs = _.union(this._allAvailTifs, [this.defaultTif()]);
                }

                if (this.defaultTifQL()) {
                    this._allAvailTifs = _.union(this._allAvailTifs, [this.defaultTifQL()]);
                }

                // Removing pluses, so that non-spot currency pairs such as
                // "AAA / BBB-T+X" will appear as "AAA / BBB-TX"
                _.each(ccPairArr, function (obj) {
                    obj.name = obj.name.replace("+", "");
                });

                this.trigger("change:currenciesOptions");
                this.trigger("change:ccPairById");
                this.trigger("change:ccPairByIdAndPlatform");
                this.trigger("change:allAvailTifs");
                this.trigger("change:ccPairNames");
                isChanged = true;
            }

            // Update streamingCurrency
            if (this._currenciesOptions instanceof Array) {
                this._streamingCurrency = this._ccPairByIdAndPlatform[this._ccPairId + "-" + this._streamerPlatform];
                this.trigger("change:streamingCurrency");
                isChanged = true;
            }

            if (data && !this._hasParameters) {
                this._hasParameters = true;
                this.trigger("initParameters");
            }

            if (isChanged) {
                this.trigger("change");
                this.trigger("change:defaultTifOrg");
                this.trigger("change:defaultTifQLOrg");
                this.trigger("change:defaultTif");
                this.trigger("change:defaultTifQL");
            }

        },

        _fixFigures: function (arr) {
            var result, i;

            if (arr instanceof Array) {
                result = arr;
                for (i = 0; i < result.length; i++) {
                    if (result[i].name.indexOf("KRW") !== -1 ||
                        result[i].name.indexOf("IDR") !== -1) {
                        result[i].postBigPriceLength = 2;
                    }
                }
            }

            return result;
        },

        _availableTifsCcPairsAsAcArrByFilter: function (filtered) {
            var plucked = _.pluck(filtered, "platformOrderTypeData"), i, arr = null, result = [],
                value, text, length;

            for (i = 0; i < plucked.length; i++) {
                if (arr === null) {
                    arr = plucked[i];
                }

                arr = _.union(arr, plucked[i]);
            }

            length = arr ? arr.length : 0;
            for (i = 0; i < length; i++) {
                value = arr[i];
                text = value.toUpperCase();
                result.push({value: value, text: text});
            }

            return result;
        },

        _getAggregations: function (arr) {
            var aggs, ccPairId, ccPairIds, i, components, result = [];

            // First, get all the templates that are marked as aggregations
            aggs = _.filter(arr, function (obj) {
                return obj.marketSrc === "Agg";
            });
            // Now, get only the ID's of the templates
            ccPairIds = _.pluck(aggs, "ccPairId");

            // For each ccPairId, get all it's agg components, and extract the platformName (marketSrc)
            for (i = 0; i < ccPairIds.length; i++) {
                ccPairId = ccPairIds[i];
                components = _.pluck(_.filter(arr, function (obj) {
                    return obj.marketSrc !== "Agg" && obj.isAgg && obj.ccPairId === ccPairId;
                }), "marketSrc");

                if (components.length > 0) {
                    result[ccPairId] = components;
                }
            }

            return result;
        },

        _doesCcPairSupportFullSum: function (ccPair, filterAgg) {
            var aggComponents,
                result = ccPair.supportFullSum;

            // If the user wishes to filter the aggregator and its
            // components from the fullSum widget, only check for
            // fullSum support on the platform. If not, this means
            // we can be less strict, and let Agg and its components in

            if (!filterAgg) {
                aggComponents = this.aggComponents(ccPair.ccPairId);
                result = result || ccPair.marketSrc === "Agg" || aggComponents.indexOf(ccPair.marketSrc) !== -1
            }

            return result;
        },

        _clearShaFromStorage: function () {
            var toRemove = [];

            // First, collect all keys in localStorage that start with "ccPair"
            _.each(_.keys(localStorage), function (key) {
                if (key.match(/^ccPair/)) {
                    toRemove.push(key);
                }
            });

            // After that, remove them all, in order to not modify
            // the collection while iterating it
            _.each(toRemove, function (key) {
                $.c("Removing key from localStorage", key);
                localStorage.removeItem(key);
            });
        }

    });

    return new ParametersHolder();
});
