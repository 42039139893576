define([
    "jQuery",
    "Underscore",
    "Backbone",
    "ws",
    "parametersHolder",
    "bluebird",
    "text!/controls/alert/alert.html",
    "text!/controls/alert/alert.css",
    "text!/controls/alert/alert.mobile.css",
    'jQueryExtender'
], function ($, _, Backbone, oWs, parametersHolder, Promise, alertTemplate, alertCss, alertMobileCss) {
    if (window.taAlert) return window.taAlert;

    var AlertView = Backbone.View.extend({
        el: $("body"),
        template: _.template(alertTemplate),
        _msgCounter: 0,
        _currMsg: null,
        _isDisplayed: false,
        _msgQueue: [],
        _focusOkButtonRAFCallbackProxy: null,
        _notifications: false,
        _notificationsTimeout: 5,
        _closingTime: 0,

        initialize: function () {
            $.loadCss(alertCss, "alert");
            $.loadCss(alertMobileCss, "alertMobile", "cssMobile");
            this._focusOkButtonRAFCallbackProxy = $.proxy(this._focusOkButtonRAFCallback, this);
            this.render();

            _.bindAll(this, "closeCurrentMsg");

//            mainTabs.on("alertCall", $.proxy(this._wsAlertCallback, this));
        },

        render: function () {
            var $el = this.$el,
                compiledTemplate;

            compiledTemplate = this.template({});
            $el.append(compiledTemplate);

            this._dialogCont = this.$el.find("#taAlert");
            this._dialogCont.addClass("theme-widget-base");
            this._templates = this.$el.find(".taAlertTemplate");
        },

        center: function () {
            try {
                this._dialogCont.dialog("option", "position", { my: "center", at: "center", of: window });
            }
            catch (e) {
                $.c("taAlert.center exception", e);
            }
        },

        focusOkButton: function () {
            requestAnimationFrame(this._focusOkButtonRAFCallbackProxy);
        },

        notifications: function (bool) {
            var result = this._notifications;

            if (typeof bool !== "undefined") {
                this._notifications = bool;
                result = this;
            }

            return result;
        },

        notificationsTimeout: function (n) {
            var result = this._notificationsTimeout;

            if (typeof n !== "undefined") {
                this._notificationsTimeout = n;
                result = this;
            }

            return result;
        },

        _focusOkButtonRAFCallback: function () {
            this._dialogCont.focus().find(".taAlertBtnOk:eq(0)").focus();
        },

        _doKeyAction: function (key, func, keyCode) {
            if (key && func && (typeof func === "function") &&
                ((typeof key === "string" && key.toUpperCase().charCodeAt(0) == keyCode) ||
                    (typeof key === "number" && key == keyCode))) {
                func();
                return false;
            }
            return true;
        },

        _processKeyAction: function (key, func, keyCode) {
            var j, res = true;
            if (key instanceof Array) {
                for (j = 0; j < key.length; j++) {
                    res = this._doKeyAction(key[j], func, keyCode);
                }
            }
            else {
                res = this._doKeyAction(key, func, keyCode);
            }
            return res;
        },

        onKey: function (k) {
            var i,
                res = true;

            if (this._currMsg.keyActions instanceof Array) {
                for (i = 0; i < this._currMsg.keyActions.length; i++) {
                    res = this._processKeyAction(this._currMsg.keyActions[i].key, this._currMsg.keyActions[i].func, k.keyCode);
                }
            }

            if (k.keyCode === 27 && !this._currMsg.hideCloseButton) {
                if (this._currMsg.close) this._currMsg.close();
                else this.closeCurrentMsg();
                this._destroyCurrentMsg();
            }

            return res;
        },

        _isDuplicateMsg: function (title, body) {
            var duplicateMsg,
                allMsgs = _.map(this._msgQueue, function (msg) { // Map to concise structure
                return {
                    title: msg.title,
                    body: msg.body
                };
            });

            if (this._isDisplayed) { // Add optional single message object
                allMsgs.push({
                    title: this._currMsg.title,
                    body: this._currMsg.messageText
                });
            }

            duplicateMsg = _.find(allMsgs, function (msg) { // Find the first duplicate
                return ((msg.title === title) && (msg.body === body));
            });

            return (undefined !== duplicateMsg);
        },

        show: function (title, body, options) {
            if (options && options.isServerMessage && this._isDuplicateMsg(title, body)) {
                $.c("Filtered server message", title, body);
            }
            else {
                if (!this._isDisplayed) {
                    return this._showMsg(this._getMsgObject(title, body, options));
                }
                else {
                    return this._addToMsgQueue(this._getMsgObject(title, body, options));
                }
            }
        },

        showPromise: function (title, body, valueOk, valueCancel, resolveParam, rejectParam) {
            return new Promise(function (resolve, reject) {
                this.show(title, body, {
                    buttons:[
                        {
                            value: valueOk || "OK",
                            click: function () {
                                resolve(resolveParam);
                            }
                        },
                        {
                            value: valueCancel || "Cancel",
                            click: function () {
                                reject(rejectParam);
                            }
                        }
                    ]
                });
            }.bind(this));
        },

        showFancy: function (title, body, options, className) {
            this.show(title, body, options);
            if (className) {
                this._dialogCont.dialog("widget").addClass(className);
            }
        },

        showFancyPromise: function (title, body, valueOk, valueCancel, resolveParam, rejectParam, className, options) {
            return new Promise(function (resolve, reject) {
                this.showFancy(title, body, {
                    buttons:[
                        {
                            value: valueCancel || "Cancel",
                            click: function () {
                                reject(rejectParam);
                            }
                        },
                        {
                            value: valueOk || "OK",
                            click: function () {
                                resolve(resolveParam);
                            }
                        }
                    ],
                    customWidth: options
                }, className);
            }.bind(this));
        },

        showOkFancyPromise: function (title, body, valueOk, resolveParam, className, options) {
            return new Promise(function (resolve) {
                this.showFancy(title, body, {
                    buttons:[
                        {
                            value: valueOk || "OK",
                            click: function () {
                                resolve(resolveParam);
                            }
                        }
                    ],
                    customWidth: options
                }, className);
            }.bind(this));
        },

        notification: function (title, body, options) {
            var notification;

            if (window.Notification && window.Notification.permission === "granted") {
                notification = new window.Notification(title, {
                    body: body.replace(/<br>/g,"\n"),
                    icon: this._getNotificationIcon()
                });
                notification.onshow = $.proxy(this._notificationDisplayCallback, this, notification);
            }
            else {
                $.c("taAlert: No permission for notification - falling back to regular alert");
                this.show(title, body, options);
            }
        },

        close: function (msgId) {
            var msgToRemoveIndex = null;
            if (this._currMsg.msgId == msgId)
                this.closeCurrentMsg();
            else if ((msgToRemoveIndex = this._findIndexByMsgId(msgId)) != -1)
                this._msgQueue.splice(msgToRemoveIndex, 1);
        },

        isActive: function () {
            return this._isDisplayed;
        },

        wasActiveLately: function () { // Returns true when the alert is either active, or was active in the past 96ms
            return this._isDisplayed || ((Date.now() - this._closingTime) <= 96);
        },

        closeCurrentMsg: function () {
            try {
                this._dialogCont.dialog("close");
            } catch (e){ }
        },

        getDialogElem: function () {
            return this._dialogCont;
        },

        triggerAlert: function (data) {
            this._wsAlertCallback(data);
        },

        _notificationDisplayCallback: function (notification) {
            setTimeout($.proxy(this._notificationDisplayTimeoutCallback, this, notification), this._notificationsTimeout * 1000);
        },

        _notificationDisplayTimeoutCallback: function (notification) {
            if (notification && typeof notification.close === "function") {
                notification.close();
            }
            else if (notification && typeof notification.cancel === "function") {
                notification.cancel();
            }
        },

        _getNotificationIcon: function () {
            var result = "/images/apple-touch-icon.png";

            if ($.isParFx()) {
                result = "/images/apple-touch-icon-parfx.png";
            }

            if (parametersHolder.theme() === "ams") {
                result = "/images/apple-touch-icon-swapex.png";
            }

            return result;
        },

        _showMsg: function (msgObj) {
            var dialogClass = "taAlertStandart theme-widget-base",
                alertBtnCont = this._templates.find(".taAlertBtnCont").clone(),
                alertBtn = null,
                i;
            msgObj.body = $("<div/>").css("margin", "0 5px").append(msgObj.body);
            this._currMsg = msgObj;
            this._isDisplayed = true;
            this._dialogCont.attr("title", msgObj.title);
            this._dialogCont.html("");
            this._dialogCont.append(msgObj.body);
            if (msgObj.dialogClass)
                dialogClass += " " + msgObj.dialogClass;

            if (!msgObj.height)
                msgObj.height = "auto";

            if (!msgObj.minHeight)
                msgObj.minHeight = "auto";

            if (!msgObj.width)
                msgObj.width = $.isMobile() ? 500 : (msgObj.customWidth ? msgObj.customWidth.customWidth : 325);

            if ((!msgObj.hideOkBtn) && ((!msgObj.buttons) || !(msgObj.buttons instanceof Array))) {
                msgObj.buttons = [];
                msgObj.buttons.push({
                    value: "OK",
                    click: $.proxy(this.closeCurrentMsg, this)
                });
            }

            if ((msgObj.buttons) && (msgObj.buttons instanceof Array)) {
                for (i = 0; i < msgObj.buttons.length; i++) {
                    alertBtn = this._templates.find(".taAlertBtnOk").clone();
                    alertBtn.val(msgObj.buttons[i].value);
                    alertBtn.click(msgObj.buttons[i].click);
                    if (msgObj.buttons[i].disabled) {
                        alertBtn.attr("disabled", "disabled");
                    }
                    alertBtnCont.append(alertBtn);
                }
                this._dialogCont.append(alertBtnCont);
            }

            this._dialogCont.dialog({
                height: msgObj.height,
                width: msgObj.width,
                minHeight: msgObj.minHeight,
                modal: true,
                resizable: false,
                closeOnEscape: false,
                dialogClass: dialogClass,
                open: $.proxy(function (event, ui) {
                    if (msgObj.hideCloseButton)
                        this._hideCloseButton(event, ui);
                    this._setDragableForTouchDevices(event, ui);
                    if (msgObj.open)
                        msgObj.open(event, ui);
                }, this),
                close: $.proxy(function (event, ui) {
                    if (msgObj.close) msgObj.close(event, ui);
                    this._destroyCurrentMsg();
                }, this)
            });

            this._dialogCont.dialog("widget").find(".ui-dialog-titlebar")
                .addClass("theme-main-color-1 theme-text-color-2")
                .end().find(".ui-dialog-titlebar-close .ui-icon")
                .addClass("taIcon-close theme-text-color-2")
                .removeClass("ui-icon ui-icon-closethick");
            //setting the alert in front of the window
            $(this._dialogCont.parent().parent().find(".ui-widget-overlay.ui-front:last")).css("z-index", 210);

            return msgObj.msgId;
        },

        _addToMsgQueue: function (msgObj) {
            this._msgQueue.push(msgObj);
            return msgObj.msgId;
        },

        _hideCloseButton: function (event, ui) {
            $(event.target).parent().find(".ui-dialog-titlebar-close").hide();
        },

        _setDragableForTouchDevices: function (event, ui) {
            var titleBar = $(event.target).parent().find(".ui-dialog-titlebar");
            if (titleBar && titleBar.addTouch) {
                titleBar.addTouch().disableSelection();
            }
        },

        _getMsgObject: function (title, body, options) {
            if (!options) options = {};
            return $.extend(options, {title: title, body: body, messageText: body, msgId: this._getNewMsgId()});
        },

        _getNewMsgId: function () {
            if (this._msgCounter > 9999999)
                this._msgCounter = 0;
            return this._msgCounter++;
        },

        _findIndexByMsgId: function (msgId) {
            var i;
            for (i = 0; i < this._msgQueue.length; i++) {
                if (this._msgQueue[i].msgId == msgId)
                    return i;
            }
            return -1;
        },

        _destroyCurrentMsg: function () {
            //$.c("destroyed taAlert");
            this._dialogCont.dialog("destroy");
            this._dialogCont.empty();
            this._isDisplayed = false;
            this._closingTime = Date.now();
            if (this._msgQueue.length > 0) {
                this._showMsg(this._msgQueue[0]);
                this._msgQueue.splice(0, 1);
            }
        },

        _wsAlertCallback: function (data) {
            if (data && data.message) {
                if (!data.title) {
                    data.title = "Warning";
                }

                if (typeof data.message === "string") {
                    data.message = data.message.replace(/\n/g, "<br>");
                }

                if (this._notifications && data.title !== "Message from admin") {
                    this.notification(data.title, data.message);
                }
                else {
                    this.show(data.title, data.message, { isServerMessage: true });
                }
            }
        }
    });

    window.taAlert = new AlertView();
    return window.taAlert;
});