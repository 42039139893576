define([
    "jQuery",
    "Underscore",
    "Backbone",
    "controls/wsng/wsng.js",
    "cacheLoaderPopup",
    "taAlert",
    "controls/connectionQuality/connectionQuality.js",
    "controls/login/loginThemeHelper.js",
    "text!/controls/login/passwordExpiredTemplate.html",
    "text!/controls/login/login.html",
    "text!/controls/login/login.css",
    "text!/controls/login/login.theme.css",
    "passwordStrength"
], function ($, _, Backbone, oWs, cacheLoaderPopup, taAlert, ConnectionQuality, LoginThemeHelper,
    PasswordExpiredTemplate, loginTemplate, loginCss, theme) {
    "use strict";

    return Backbone.View.extend({

        el: "body",
        template: _.template(loginTemplate),
        themeTemplate: _.template(theme),

        _accessKey: null,
        _isAttemptingLogin: false,
        _splashElem: null,
        _logoElem: null,
        _sentLogin: false,
        _connectionQuality: null,
        _errorStrings: null,
        _ssoForm: null,
        _ssoElem: null,
        _passwordExpiredTemplate: _.template(PasswordExpiredTemplate),

        _isUserApproved: false,

        _LATEST_CHROME_VERSION: 37,
        _isFormSubmitted: false,

        initialize: function () {
            $.loadCss(loginCss, "login");

            this._accessKey = this._extractAccessKeyFromUrl();
            this._handleStorage();

            this._connectionQuality = new ConnectionQuality();
            oWs.connectionQuality(this._connectionQuality);

            this.render();

            if (cacheLoaderPopup) {
                this.listenTo(cacheLoaderPopup, "manifestError", this._manifestErrorCallback);
                this.listenTo(cacheLoaderPopup, "manifestProgress", this._manifestProgressCallback);
            }

            this._setWSEvents();
            LoginThemeHelper.determineLogoByUrl(this.$el, this._logoElem);

            localStorage.setItem("commitId", window.taCommit);

            this._errorStrings = {
                "wrong password": "The user name or password that you have entered is incorrect",
                "user is locked": "User account is locked, please contact support",
                "user is disabled": "User account is disabled, please contact support",
                "already login": "You are already logged in on a different computer."
            }
            _.bindAll(this, "_passwordExpiredSubmitNewPassword", "_expiredPasswordNewPasswordHandler", "_expiredPasswordErrorHandler");
        },

        render: function () {
            var compiledTemplate = $(_.template(loginTemplate, {}));
            this.$el.prepend(compiledTemplate);

            this._evaluateElements();
            this._hideLoading();
            LoginThemeHelper.setUpLogo(this.$el, this._splashElem);
            this._checkChromeVersion();
            this._setUpConnectionQuality();

            if (window.showTlaLink !== "true") {
                this.$el.find(".login_tlaLink").remove();
            }
        },

        events: {
            "submit .login_loginBox": "_loginBoxSubmitCallback",
            "keyup .passwordExpired_passwordOne": "_passwordValidationTester"
        },

        _evaluateElements: function () {
            var $el = this.$el;

            this._loginBox = $(".login_loginBox");
            this._passwordInput = $(".login_loginFieldInputBInput");
            this._emailInput = $(".login_loginFieldInputEmail");
            this._loginErrorTimeout = $el.find(".login_loginErrorTimeout");
            this._loginErrorVersion = $el.find(".login_loginErrorVersion");
            this._connecting = $el.find(".login_connecting");
            this._splashElem = $el.find(".login_splash");
            this._logoElem = $el.find(".login_tradAirLogo");
            this._ssoForm = $(".login_ssoForm");
            this._ssoElem = this.$el.find(".login_sso");
        },

        _loginBoxSubmitCallback: function (e) {
            var userName, password;
            if (this._isFormSubmitted) {
                if (!this._isUserApproved) {
                    e.preventDefault();
                }
                return;
            }

            // First and foremost, show loading
            this._showLoading();

            // Callback for submitting the login form. When a user submits it,
            // we would like to prevent the default behaviour, and instead of
            // redirecting according to the form's action attribute, we would
            // send a webSocket call to TNET to validate the user's credentials.

            // If TNET will approve the username and password, we will get back
            // to this function, and this time we DO want the form's default
            // behavior and redirect the user - thus not the preventDefault method.

            if (!this._isUserApproved) {
                e.preventDefault();
                userName = this._emailInput.val();
                password = this._passwordInput.val();
                this._authenticate(userName, password, null, null, null, null);
                this._isFormSubmitted = true;
            }
        },

        _getMachineId: function () {
            var vM = sessionStorage.getItem("taMachineId");
            if (!vM) vM = $.loginGuid();
            sessionStorage.setItem("taMachineId", vM);
            return vM;
        },

        _authenticate: function (email, password, accessKey, ssoUserName, ssoTimeStamp, ssoGuid) {
            var version = null, userParams, success, fail;

            try {
                version = window.navigator.appVersion.match(/Chrome\/(.*?) /)[1];
            }
            catch (e) { }

            userParams = {
                username: email,
                password: password,
                accessKey: accessKey,
                machine: this._getMachineId(),
                version: version,
                branch: window.taBranch,
                commit: window.taCommit,
                ssoUserName: ssoUserName,
                ssoTimeStamp: ssoTimeStamp,
                ssoGuid: ssoGuid
            };

            success = $.proxy(this._authenticateSuccess, this, userParams);
            fail = $.proxy(this._authenticateFail, this, userParams);

            oWs.sendMessage("/authenticate", userParams)
                .then(success)
                .catch(fail);
        },

        _authenticateSuccess: function (params, data) {
            this._evaluateElements();
            this._hideLoading();
            this._handleSuccessfulAuthenticate(params, data);
        },

        _authenticateFail: function (params, message) {
            var body;
            this._isFormSubmitted = false;
            if (message === "Password has expired") {
                body = "Your password has expired. Please submit a new password.";
                this._handlePasswordExpired(params, body);
                this._hideLoading();
                return false;
            }

            if (message === "First login") {
                body = "First login. Please enter you password.";
                this._handlePasswordExpired(params, body);
                this._hideLoading();
                return false;
            }

            this._showLoginForm();
            this._evaluateElements();
            this._hideLoading();
            this._showLoginError(message);
        },

        _passwordValidationTester: function (e) {
            var input = e.target,
                value = e.target.value;
            if ($.passwordValidator(value)) {
                input.classList.remove("login_redborder");
            } else {
                input.classList.add("login_redborder");
            }
        },

        _handlePasswordExpired: function (loginParams, body) {
            var template = _.template(PasswordExpiredTemplate, { body: body });
            taAlert.showFancy("Reset password", template, {
                buttons: [
                    {
                        value: "Submit",
                        click: this._passwordExpiredSubmitNewPassword.bind(this, loginParams)
                    },
                    {
                        value: "Cancel",
                        click: this._passwordExpiredCancel
                    }
                ],
                width: 500
            }, "fancyElixium");
            this.$el.find("#passwordExpiredTemplate_passwordStrength").strength({
                strengthMeterClass: 'passwordExpiredTemplate_cont passwordStrengthMeter'
            });
            this.$el.find('div[data-meter="passwordExpiredTemplate_passwordStrength"]').removeClass().html("");
        },

        _passwordExpiredSubmitNewPassword: function (loginParams) {
            var passwordOne = this.$el.find(".passwordExpired_passwordOne").val(),
                passwordTwo = this.$el.find(".passwordExpired_passwordTwo").val(),

                oldPassword = loginParams.password,
                username = loginParams.username,
                data;

            if (!$.passwordValidator(passwordOne)) {
                this._showExpiredPasswordError("Must be at least 8 chars long, <br> contain a number, a small letter, a capital letter");
                return false;
            }

            if (passwordOne !== passwordTwo) {
                this._showExpiredPasswordError("Passwords do not match");
                return false;
            }


            data = {
                username: username,
                oldPassword: oldPassword,
                newPassword: passwordOne
            }

            this._disableExpiredPasswordInputs();

            oWs.sendMessage("/expiredPassword", data)
                .then(this._expiredPasswordNewPasswordHandler)
                .catch(this._expiredPasswordErrorHandler)
        },

        _showExpiredPasswordError: function (message) {
            var messageCont = this.$el.find(".login_passwordExpiredMessage");
            messageCont.html("");
            messageCont.html(message).animate({ opacity: 1 }, 200, "swing", setTimeout(
                function () {
                    this.$el.find(".login_passwordExpiredMessage").animate({ opacity: 0 }, 3000);
                }.bind(this), 3000
            ))
        },

        _expiredPasswordErrorHandler: function (data) {
            this._enableExpiredPasswordInputs();
            this._showExpiredPasswordError(data);
        },

        _passwordExpiredCancel: function () {
            taAlert.closeCurrentMsg();
        },

        _disableExpiredPasswordInputs: function () {
            $(".passwordExpiredTemplate_passwordInput").prop("disabled", true);
        },

        _enableExpiredPasswordInputs: function () {
            $(".passwordExpiredTemplate_passwordInput").prop("disabled", false);
        },

        _expiredPasswordNewPasswordHandler: function (data) {
            taAlert.closeCurrentMsg();
            taAlert.showFancy("Password reset", "Password reset was successful. Redirecting...", {}, "fancyElixium");

            setTimeout(function () {
                this._authenticate(data.username, data.newPassword, null, null, null, null)
            }.bind(this), 2000);
        },

        _handleSuccessfulAuthenticate: function (params, data) {
            var isAdmin;

            this._generateResourceMap(data.user);

            sessionStorage.setItem("taSessionId", data.sessionId);
            sessionStorage.setItem("taLogin", JSON.stringify(data));

            isAdmin = $.hasPermission("SHOW_ADMIN");
            this.$el.find(".login_system").val(isAdmin ? "adminNg" : "ng");

            if (params.ssoUserName) {
                this._ssoElem.find(".spinner").css("visibility", "visible");

                _.delay(function (formElem) {
                    formElem.submit();
                }, 3000, this._ssoForm);
            }
            else {
                this._isUserApproved = true;
                this._loginBox.submit();
            }

            this._connecting.hide();
        },

        _generateResourceMap: function (user) {
            var ids = _.pluck(_.flatten(_.pluck(user.roles, "resources")), "id"),
                enums = _.pluck(_.flatten(_.pluck(user.roles, "resources")), "resourceEnum");

            // Converting list to map, in order not to loop each and every time

            user.resourcesMap = {};

            _.each(ids, function (id) { user.resourcesMap[id] = true; });
            _.each(enums, function (enums) { user.resourcesMap[enums] = true; });

        },

        _showLoading: function () {
            this.$el.find(".taSprite_loginSubmitIcon").hide();
            this.$el.find(".login_loginFieldInputBLoading").show();
        },

        _hideLoading: function () {
            this.$el.find(".taSprite_loginSubmitIcon").show();
            this.$el.find(".login_loginFieldInputBLoading").hide();
        },

        _showLoginForm: function () {
            this._connecting.hide();

            if (this._isSso()) {
                this._ssoElem.show();
            }
            else {
                this._loginBox.show();
                this.$el.find("input").filter(":visible:first").focus();
            }
        },

        _addStatus: function (s) {
            $.c("loginStatus", s);
        },

        _setWSEvents: function () {
            oWs.open();
            this._addStatus("Opening Connection");
            oWs.on("open", $.proxy(this._onOpen, this));
            oWs.on("shutdown", $.proxy(this._onShutdown, this));
            oWs.on("close", $.proxy(this._onClose, this));
            oWs.on("error", $.proxy(this._onError, this));
            oWs.on("callbackTimeout", $.proxy(this._onCallbackTimeout, this));
        },

        _onOpen: function () {
            var callback, fail;

            this._addStatus("Connection opened");

            // I honestly don't know why, but this call sometimes causes chrome to crash, due to a wierd socket.io issue (Maybe race condition?) This timeout helps us avoid this issue, so please do not remove :-)
            callback = $.proxy(this._helloWorldCallback, this);
            fail = $.proxy(this._handshakeFail, this);

            setTimeout(function () {
                oWs.sendMessage("/handshake")
                    .then(callback)
                    .catch(fail);
            }, 500);

        },

        _helloWorldCallback: function (data) {
            this._checkIfVersionIsLatest(data);
            this._setNodeProperties(data);
            this._addStatus("Server ready!");
            //this._syncConnectionQuality();
            localStorage.setItem("envType", data.envType);
            localStorage.setItem("serverVersion", data.presentationVersion);
            this.$el.find(".versionNumber").html(data.presentationVersion);
            this._startLoginProcess();
        },

        _syncConnectionQuality: function () {
            this._connectionQuality.resetSyncData();
            this._connectionQuality.sync(30);
        },

        _checkIfVersionIsLatest: function (data) {
            var serverVersion,
                clientVersion;

            try {
                serverVersion = data.reportRes.version;
                clientVersion = (window.taBranch && window.taCommit) ? window.taBranch + "-" + window.taCommit : null;
                localStorage.setItem("taClientVersion", clientVersion);
                if (serverVersion && clientVersion && serverVersion !== clientVersion) {
                    $.c("Warning! Not using the latest GUI version!");
                    this._loginErrorVersion.fadeIn();
                }
                $.c("Server Version: ", serverVersion, " Client Version", clientVersion);
            }
            catch (e) {
                $.c("login._checkIfVersionIsLatest Exception", e);
            }
        },

        _setNodeProperties: function (data) {
            if (data && data.reportRes) {
                sessionStorage.setItem("pingTimeout", data.reportRes.pingTimeout);
                sessionStorage.setItem("pingInterval", data.reportRes.pingInterval);
                sessionStorage.setItem("gracePeriod", data.reportRes.gracePeriod);
            }
        },

        _startLoginProcess: function () {
            // A connection has been established to the server, and
            // the login process can begin. In most cases, we would
            // simply show the login form to the user in order to fill
            // in user name and password. In other cases, we want to
            // initiate an automated login process - accessKey for
            // mobile users or SSO for corporate clients.

            var ssoDetails = this._getSsoDetails(),
                isSso = this._isSso();

            if (isSso) {
                this._showLoginForm();
                this._initiateSsoProcess(ssoDetails);
            }
            else if (this._accessKey !== null) {
                this._checkAccessKeyAndRedirectIfNeeded();
            }
            else {
                this._showLoginForm();
            }

        },

        _onShutdown: function () {
            this._addStatus("Connection Shutdown");
            this._showDisconnectMessage();
        },

        _onClose: function () {
            this._addStatus("Open connection failed");
            this._showDisconnectMessage();
        },

        _onError: function () {
            this._addStatus("Connection onError");
        },

        _showDisconnectMessage: function () {
            this._loginBox.hide();
            this._connecting.html("Cannot connect to server");
            this._splashElem.hide();
        },

        _extractAccessKeyFromUrl: function () {
            var href = window.location.href.toLowerCase(),
                url = href.split("?"),
                accessKey,
                accessKeyValue = null;

            sessionStorage.removeItem("accessKey");

            if (url instanceof Array && url.length > 1) {
                accessKey = url[1].split("accesskey=");

                if (accessKey instanceof Array && accessKey.length > 1) {
                    accessKeyValue = accessKey[1];
                }
            }

            return accessKeyValue;
        },

        _checkAccessKeyAndRedirectIfNeeded: function () {
            this._accessKey = sessionStorage.getItem("accessKey");

            if (this._accessKey !== null) {
                if (!this._sentLogin) {
                    this._sentLogin = true;
                    this._authenticate(null, null, this._accessKey, null, null, null);
                }
            }
        },

        _checkChromeVersion: function () {
            var fullVer = this._isChromeVersionInvalid();

            if (fullVer) {
                this.$el.find(".login_chromeVersionMsg").show().find(".login_chromeVersionMsg_ver").text(fullVer);
            }
        },

        _manifestErrorCallback: function () {
            this._loginBox.remove();
            this._splashElem.hide();
            this._connecting.html("Cannot connect to web server").show();
        },

        _manifestProgressCallback: function () {
            this.stopListening(cacheLoaderPopup, "manifestError")
        },

        _onCallbackTimeout: function (datatype, readableDataType) {
            if (datatype === "46d628e4-bbb0-11e1-be33-5cac4cc8abd1" || readableDataType === "AUTHENTICATE") {
                this._loginErrorTimeout.fadeIn();
                this._hideLoading();
                setTimeout($.proxy(this._loginErrorTimeout.fadeOut, this._loginErrorTimeout), 5000);
            }
            else if (datatype === "96d96cfc-bb82-11e1-be33-5cac4cc8abd1" || readableDataType === "HELLOWORLD") {
                this._loginBox.remove();
                this._splashElem.hide();
                this._connecting.html("Error connecting to server").show();
            }
        },

        _setUpConnectionQuality: function () {
            this.$el.find(".login_cont").append(this._connectionQuality.$el);
            this._connectionQuality.render().start();
            this._connectionQuality.tooltip(window.location.host, window.taBranch, window.taCommit);
        },

        _handleStorage: function () {
            localStorage.removeItem("taSessionId");
            sessionStorage.removeItem("taSessionId");
            sessionStorage.removeItem("sessionId");
            sessionStorage.removeItem("taLogin");
            localStorage.removeItem("timingSkew");

            if (this._accessKey) {
                localStorage.setItem("accessKey", this._accessKey);
            }

            if (this._accessKey === null) {
                this._accessKey = localStorage.getItem("accessKey");
            }

            sessionStorage.removeItem("inSession");
        },

        _isSso: function () {
            var ssoDetails = this._getSsoDetails();

            return !!ssoDetails.ssoUserName || !!ssoDetails.ssoGuid;
        },

        _getSsoDetails: function () {
            // In case of SSO (Single Sign On), we will get a POST
            // request with the details. See index.node.js for more
            // information.

            return {
                ssoUserName: window.ssoUserName || null,
                ssoTimeStamp: window.ssoTimeStamp || null,
                ssoGuid: window.ssoGuid || null
            };
        },

        _initiateSsoProcess: function (sso) {
            // First, check for chrome version.

            var ver = this._isChromeVersionInvalid();

            if (ver) {
                this._showLoginError("You're using Chrome version " + ver + " which is not the latest.");
            }
            else {
                this._authenticate(null, null, null, sso.ssoUserName, sso.ssoTimeStamp, sso.ssoGuid);
            }
        },

        _isChromeVersionInvalid: function () {
            var result = false;

            try {
                var ver = parseInt(window.navigator.appVersion.match(/Chrome\/(\d+)\./)[1], 10),
                    fullVer = window.navigator.appVersion.match(/Chrome\/(.*?) /)[1];

                if (!$.isMobile() && ver < this._LATEST_CHROME_VERSION) {
                    result = fullVer;
                }
            }
            catch (e) {
                $.c("login._isChromeVersionValid exception", e);
            }

            return result;
        },

        _showLoginError: function (errorString) {
            const errStr = this._errorStrings[errorString.toLowerCase()]
            this.$el.find(".login_loginErrorText").text(errStr).fadeIn().delay(2000).fadeOut();
            this.$el.find(".login_ssoError").text(errStr);
        },

        _handshakeFail: function () {
            this._loginBox.remove();
            this._splashElem.hide();
            this._connecting.html("Error connecting to server").show();
        }
    });
});