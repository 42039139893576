define([
    "controls/login/login.js",
    "text!/controls/loginElixium/loginElixium.css",
], function (Login, loginElixiumCss) {

    "use strict";

    return Login.extend({

        initialize: function () {
            Login.prototype.initialize.call(this);
        },

        render: function () {
            Login.prototype.render.call(this);
            this._addBgAndText();
            this.$el.find(".login_fieldsLogin h1").remove();

            $.loadCss(loginElixiumCss, "loginElixiumCss");

            return this;
        },

        _addBgAndText: function () {
            var towerBgContDiv = $("<div class='elixium-tower-bg-cont'/>"),
                towerBg = $("<div class='elixium-tower-bg'/>"),
                textCont = $("<div class='elixium-tower-text'/>"),
                textDiv = $("<div class='elixium-tower-text2'/>");

            this.$el.find(".login_cont").after(towerBgContDiv);
            towerBgContDiv.append(towerBg).append(textCont);

            var text = "";
            textDiv.html(text + "<div class='versionNumber'></div>");
            textCont.html(textDiv);
        }

    });

});